import React, {useState, useEffect} from 'react'
import {Button} from 'reactstrap';
import usePostHttpRequest from '../hooks/usePostHttpRequest';

export default function ProspectoVisibleToggler({vacanteId, prospectoId, visible, ...props}) {

    const [status, setStatus] = useState(visible);
    const [working, doPost] = usePostHttpRequest();

    useEffect(() => {
        setStatus(visible);
    }, [visible])

    async function handleClick() {
        await doPost(`/prospectos/prospecto/${vacanteId}/${prospectoId}/visible`, {
            visible: !status
        })
        setStatus(!status);
    }

    return (
        <Button {...props} className="btn-white"
            disabled={working}
            onClick={handleClick}>
            {status ? 'Visible' : 'No visible'}
        </Button>
    )
    
}