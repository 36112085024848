import React from 'react';
import FastInput from '../shared/FastInput';
import FlexRow from '../shared/FlexRow';

export default function ContactoTelefono({value, onChange}) {

    function handleChange(name) {
        return function(event) {
            onChange({...value, [name]:event.target.value})
        }
    }

    return (
        <FlexRow>
            {
                value ? 
                <React.Fragment>
                    <FastInput placeholder="Nombre" type="text"
                    value={value.nombre}
                    onChange={handleChange('nombre')}
                    ></FastInput>
                    <FastInput placeholder="Teléfono" type="phone"
                    value={value.telefono}
                    onChange={handleChange('telefono')}
                    ></FastInput>
                </React.Fragment> : null
            }
            
        </FlexRow>
    )

}