import React from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import FlexRow from '../shared/FlexRow'

const LABELS = {
    iniciativa: 'Iniciativa',
    puntualidad: 'Puntualidad',
    asistencia: 'Asistencia',
    adaptabilidad: 'Adaptabilidad',
    calidadTrabajo: 'Calidad de trabajo',
    honradez: 'Honradez',
    colaboracion: 'Colaboración',
    responsabilidad: 'Responsabilidad',
    proyeccion: 'Proyección',
    actitudJefe: 'Actitud hacia el jefe',
    actitudCompaneros: 'Actitud hacia sus compañeros',
    actitudSubordinados: 'Actitud hacia sus subordinados'
}

export default function ReferenciaCalificacion({tabId, name, value, onChange}) {

    return (
        <FormGroup className="ReferenciaCalificacion">
            <FlexRow justifyContent="space-around" flexWrap="nowrap">                        
                <div style={{width:'150px'}}>{LABELS[name]}</div>
                <FormGroup check>
                    <Label check>
                      <Input type="radio" 
                      name={tabId + name} 
                      value="malo"
                      checked={value == 'malo'}
                      onChange={onChange}/>{' '}
                      Malo
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                      <Input type="radio" 
                      name={tabId + name} 
                      value="bueno"
                      checked={value == 'bueno'}
                      onChange={onChange} />{' '}
                      Bueno
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                      <Input type="radio" 
                      name={tabId + name} 
                      value="excelente"
                      checked={value == 'excelente'}
                      onChange={onChange} />{' '}
                      Muy Bueno
                    </Label>
                </FormGroup>
            </FlexRow>                    
        </FormGroup>
    )

}