import React from 'react';
import {Button} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './BorderButton.module.css'

export default function BorderButton({icon, children, green, red, ...rest}) {

    return (
        <Button className={`${styles.button} ${green ? styles.green : ''} ${red ? styles.red : ''}`} {...rest}>
            <FontAwesomeIcon icon={icon} className={styles.content}></FontAwesomeIcon>
            <span className={styles.content}>{children}</span>
        </Button>
    )

}