import React, {useState, useEffect} from 'react';
import FlexRow from '../shared/FlexRow';
import Checkbox from '../shared/Checkbox';
import FlexColumn from '../shared/FlexColumn'
import styles from './RequisicionForm.module.css'

const ley = [
    'Aguinaldo 15 días (Art. 76)',
    'Prima vacacional (Art. 76)',
    'Seguro Social',
    'Utilidades',
    'Días de descanso ',
    'Prima dominical'
]

const superiores = [
    'Aguinaldo superior a 15 días',
    'Días adicionales de vacaciones ',
    'Días adicionales de descanso',
    'Vales de gasolina',
    'Vales de despensa ',
    'Bono de productividad',
    'Bono de puntualidad y asistencia',
    'Apoyo a la vivienda (Renta)',
    'Caja de ahorro ',
    'Apoyo de transporte',
]


export default function RequisicionPrestaciones({value, onChange, showDirty}) {

    const [dirty, setDirty] = useState(false);

    const [prestaciones, setPrestaciones] = useState({
        ley: ley.map(l => ({
            checked: false,
            nombre: l
        })),
        superiores: superiores.map(s => ({
            checked: false,
            nombre: s
        }))
    })

    useEffect(() => {
        if(value) {
            setPrestaciones({
                ley: ley.map(l => ({
                    checked: value.ley.includes(l),
                    nombre: l
                })),
                superiores: superiores.map(l => ({
                    checked: value.superiores.includes(l),
                    nombre: l
                }))
            })
        }
    }, [value])

    function handleCheckboxChange(name, val, i) {
        return function(event) {
            setDirty(true);
            const newValue = prestaciones[name].slice();
            newValue[i].checked = event.target.checked;            
            if(event.target.checked) {
                const newSelected = [...value[name], val];
                onChange({...value, [name]:newSelected});
            } else {
                const newSelected = value[name].filter(v => v !== val);
                onChange({...value, [name]:newSelected});
            }
        }
    }

    return (
        <div className={`${dirty && showDirty ? styles.sectionDirty : ''}`}>
            <h6>Prestaciones</h6>
            <FlexRow alignItems="flex-start">
                <FlexColumn>
                    <h6>Ley</h6>
                    {
                        prestaciones.ley.map((l,i) => (
                            <Checkbox key={i} checked={l.checked}
                                onChange={handleCheckboxChange('ley', l.nombre, i)}>{l.nombre}</Checkbox>
                        ))
                    }
                    <br/>
                </FlexColumn>
                <FlexColumn>
                    <h6>Superiores</h6>
                    {
                        prestaciones.superiores.map((s,i) => (
                            <Checkbox key={i} checked={s.checked}
                                onChange={handleCheckboxChange('superiores', s.nombre, i)}>{s.nombre}</Checkbox>
                        ))
                    }
                    <br/>
                    
                </FlexColumn>
            </FlexRow>
        </div>
    )

}