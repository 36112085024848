import React, {useState, useEffect} from 'react';
import {calcFechaLimite} from '../shared/Utils'
import moment from 'moment';

export default function RemainingDays({fecha, tiempoSeleccion, tiempoPause}) {

    const [days, setDays] = useState(0);
    
    useEffect(() => {
        const d1 = moment();
        const d2 = moment(calcFechaLimite(fecha, tiempoSeleccion, tiempoPause));
        setDays(d2.diff(d1, 'days'));
    }, [fecha, tiempoSeleccion, tiempoPause])

    return (
        <div className="RemainingDays">
            {days}
        </div>
    )
}