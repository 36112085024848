import React, {useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Label, Form } from 'reactstrap';
import FlexRow from '../shared/FlexRow'
import FlexColumn from '../shared/FlexColumn';
import IconButton from '../shared/IconButton';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HttpRequest from '../shared/HttpRequest';

export default function VacanteInviteModal({modal, onCancel, onInvited}) {

    const [data, setData] = useState({
        nombre: '',
        paterno:'',
        materno:'',
        email: ''
    });
    const [working, setWorking] = useState(false);

    function handleChange(name) {
        return function(event) {
            setData({...data, [name]:event.target.value});
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        setWorking(true)
        try {
            await HttpRequest.post('/prospectos', {
                vacanteId: modal.vacanteId,
                ...data
            })
            setWorking(false)
            onInvited();
        } catch(e) {
            console.log(e);
            setWorking(false)
        }        
    }

    return (
        <Modal isOpen={modal.open}>
            <ModalBody>
                <FlexRow justifyContent="flex-end">
                    <div className="FontGray">
                       <IconButton icon={faTimesCircle} onClick={onCancel}></IconButton> 
                    </div>
                </FlexRow>
                <FlexColumn className="ModalContent Center" justifyContent="center">                
                    <FlexRow>
                        <FontAwesomeIcon className={'IconButton FontBlue'} style={{fontSize:'30px'}} icon={faEnvelope}></FontAwesomeIcon>
                        <div className="FontBlue Bold" style={{marginLeft:'10px'}}>Invitar Participante</div>
                    </FlexRow>
                    <br/>
                    <p>
                        Escribe aquí los datos de la persona que quieres invitar a particiar en la vacante de:
                    </p>
                    <p className="FontGreen Bold">{modal.vacanteEmpresa} - {modal.vacantePuesto}</p>
                    <Form onSubmit={handleSubmit}>
                        <FormGroup style={{textAlign:'left'}}>
                            <Label>Nombre</Label>
                            <Input 
                            value={data.nombre}
                            onChange={handleChange('nombre')}
                            required></Input>
                        </FormGroup>
                        <FormGroup style={{textAlign:'left'}}>
                            <Label>Apellido paterno</Label>
                            <Input 
                            value={data.paterno}
                            onChange={handleChange('paterno')}
                            required></Input>
                        </FormGroup>
                        <FormGroup style={{textAlign:'left'}}>
                            <Label>Apellido materno</Label>
                            <Input 
                            value={data.materno}
                            onChange={handleChange('materno')}
                            required></Input>
                        </FormGroup>
                        <FormGroup style={{textAlign:'left'}}>
                            <Label>Correo</Label>
                            <Input 
                            type="email" 
                            value={data.email}
                            onChange={handleChange('email')}
                            required></Input>
                        </FormGroup>
                        <br/>
                        <Button className="btn-blue" type="submit" disabled={working}>ENVIAR</Button>                    
                    </Form>                    
                </FlexColumn>
            </ModalBody>
        </Modal>
    )

}