import React, {useState, useEffect} from 'react';
import { Input } from 'reactstrap';
import styles from './FastInput.module.css';

export default function FastInput(props) {

    const [value, setValue] = useState(props.value)
    // const [dirty, setDirty] = useState(false);

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    function handleKeyDown(event) {
        if(event.key === 'Enter') {            
            console.log('try submit')
            props.onChange({
                target:{
                    value
                }
            });
        }  
    }

    function handleChange(event) {
        // setDirty(true);
        setValue(event.target.value)              
    }

    function handleOnBlur() {
        if(props.value === value) {
            return;
        }
        props.onChange({
            target:{
                value
            }
        });
    }

    return (
        <Input {...props}
            // className={`${className} ${dirty ? styles.dirty : ''}`}             
            value={value}            
            onKeyDown={handleKeyDown}
            onChange={handleChange} 
            onBlur={handleOnBlur}/>
    )
}