import React, {useState, useEffect} from 'react';
import FlexRow from './FlexRow';
import Checkbox from './Checkbox';

export default function CheckboxGroup({min, max, options, onChange, hideCount, value, disabled}) {
    
    const [selected, setSelected] = useState([])
    const [checkBoxOptions, setCheckBoxOptions] = useState([])

    useEffect(() => {
        setCheckBoxOptions(options.map(o => ({
            name: o,
            checked: false
        })))     
    }, [options])

    useEffect(() => {
        if(value && value.length > 0) {
            setSelected([...value])            
        }        
    }, [value])

    useEffect(() => {
        setCheckBoxOptions(options.map(o => ({
            name: o,
            checked: selected ? selected.includes(o) : false
        })))
    }, [selected, options])

    function handleChange(v, i) {
        return function(event) {  
            if(event.target.checked) {
                if((!max || selected.length < max)) {
                    const newSelected = [...selected, options[i]];                
                    setSelected(newSelected)
                    if(!min || newSelected.length >= min) {
                        onChange(newSelected)
                    } else {
                        onChange([])
                    }
                }
            } else {
                // const sel = checkBoxOptions.slice();
                // sel[i].checked = false;
                // setCheckBoxOptions(sel);
                const newSelected = selected.filter(s => s != v)
                setSelected(newSelected);
                if(!min || newSelected.length >= min) {
                    onChange(newSelected)
                } else {
                    onChange([])
                }
            }            
        }
    }

    return (
        <div>
            {
                hideCount ? null : <p>Seleccionados: {selected.length}</p>
            }            
            <FlexRow>
                {
                    checkBoxOptions.map((o, i) => (
                        <Checkbox style={{width:'300px'}} 
                            checked={o.checked}
                            onChange={handleChange(o.name, i)}
                            key={i}
                            disabled={disabled}>{o.name}</Checkbox>
                    ))
                }
            </FlexRow>
        </div>        
    )
}