import React, {useState, useCallback} from 'react';

export default function useToggle(initialValue) {

    const [val, setVal] = useState(initialValue);

    const toggle = useCallback(function() {
        setVal(!val)
    }, [val])

    return [val, toggle]
}