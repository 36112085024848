import React, {useState, useEffect, useCallback} from 'react';
import {Table, Button} from 'reactstrap';
import HttpRequest from '../shared/HttpRequest';
import useToggle from '../hooks/useToggle';
import AddPostAsignacionModal from './AddPostAsignacionModal';
import DownloadFileButton from '../shared/DownloadFileButton';
import SectionHeader from '../shared/SectionHeader'
import PostAsignacionGarantia from './PostAsignacionGarantia';
import moment from 'moment';

export default function PostAsignacion({events, prospectoId, vacanteId}) {

    const [asignaciones, setAsignaciones] = useState([]);
    const [isModalOpen, toggleModal] = useToggle(false);
    const [active, setActive] = useState(null);

    const fetchAsignaciones = useCallback(async () => {
        const response = await HttpRequest.get(`/postasignaciones/${vacanteId}/${prospectoId}`)
        setAsignaciones(response.data);
    }, [prospectoId, vacanteId])
    
    useEffect(() => {        
        if(prospectoId && vacanteId) {
            fetchAsignaciones();
        }        
    }, [events, fetchAsignaciones, prospectoId, vacanteId])

    function handleAddClick(a) {
        return function(event) {
            setActive(a._id);
            toggleModal();
        }        
    }

    function handleAdded() {
        toggleModal();
        setAsignaciones([]);
        fetchAsignaciones();
    }

    return (
        <div>
            <SectionHeader textAlign="left" subtitle="Elige los días de garantía otorgados">
                POST ASIGNACIÓN
            </SectionHeader>   
            <PostAsignacionGarantia
                prospectoId={prospectoId}
                vacanteId={vacanteId}>
            </PostAsignacionGarantia>            
            <br/>
            <h6 className="Left FontBlue">Encuestas Post Asignación</h6>
            <Table>
                <tbody>
                    {
                        asignaciones.map(a => (
                            <tr key={a._id}>
                                <td>
                                    {`${moment(a.inicio).format('DD/MM/YYYY HH:mm')}`}<br/>
                                    <Button color="link" onClick={handleAddClick(a)}>+ Agregar</Button>
                                </td>
                                <td>
                                    <Table>
                                        <tbody>
                                            {
                                                a.oficios.length == 0 ?
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                </tr> :
                                                a.oficios.map((o,i) => (
                                                    <tr key={i}>
                                                        <td>
                                                            {o.name}
                                                        </td>
                                                        <td>
                                                            <DownloadFileButton link={o.documento}>DESCARGAR OFICIO</DownloadFileButton>
                                                        </td>
                                                    </tr>
                                                ))
                                            }                                                                              
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <AddPostAsignacionModal isOpen={isModalOpen} 
                postAsignacionId={active}
                onComplete={handleAdded}
                onCancel={toggleModal}></AddPostAsignacionModal>
        </div>
    )
}