import React, { useState, useEffect } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, Form, FormGroup,
    Label, Input
} from 'reactstrap';
import FlexRow from '../shared/FlexRow';
import FlexColumn from '../shared/FlexColumn';
import FileInput from '../shared/FileInput'
import usePostHttpRequest from '../hooks/usePostHttpRequest';
import useFetch from '../hooks/useFetch';
import DownloadFileButton from '../shared/DownloadFileButton'

export default function AddAsignacionModal({ prospectoId, vacanteId, isOpen, onComplete, onCancel }) {

    const [state, setState] = useState({
        empresa: '',
        domicilio: '',
        rfc: '',
        registroPatronal: '',
        contacto: '',
        puestoContacto: '',
        colaborador: '',
        puestoAsignado: '',
        sueldo: '',
        fechaAsignacion: '',
        fechaImss: new Date(),
        reclutador: '',
        contratoLaboralFecha: new Date(),
        altaImssFecha: new Date(),
        onboardingFecha: new Date(),
        facturaFecha: new Date()
    })
    const [files, setFiles] = useState({
        contratoLaboral: null,
        altaImss: null,
        onboarding: null,
        factura: null
    })

    const [working, doPost] = usePostHttpRequest();
    const [fetching, response, doFetch] = useFetch(null)
    const [error, setError] = useState('');
    const [dirty, setDirty] = useState(false);

    useEffect(() => {
        if (vacanteId && prospectoId) {
            doFetch(`/resultadosFinales/asignacion/${vacanteId}/${prospectoId}`);
        }
    }, [vacanteId, prospectoId])

    useEffect(() => {
        if (response) {            
            setState(response);
            setFiles({
                contratoLaboral: response.contratoLaboral,
                altaImss: response.altaImss,
                onboarding: response.onboarding,
                factura: response.factura
            })
        }
    }, [response])

    function handleChange(name) {
        return function (event) {
            setState({ ...state, [name]: event.target.value })
        }
    }

    function handleFileChange(name) {
        return function (file) {
            setFiles({ ...files, [name]: file })
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        if (!files) {
            setError('Todos los campos son obligatorios')
            return;
        }
        const formData = new FormData();
        formData.append('contratoLaboral', files.contratoLaboral)
        formData.append('altaImss', files.altaImss)
        formData.append('onboarding', files.onboarding)
        formData.append('factura', files.factura)
        formData.append('asignacion', JSON.stringify({
            empresa: state.empresa,
            domicilio: state.domicilio,
            rfc: state.rfc,
            registroPatronal: state.registroPatronal,
            contacto: state.contacto,
            puestoContacto: state.puestoContacto,
            colaborador: state.colaborador,
            puestoAsignado: state.puestoAsignado,
            sueldo: state.sueldo,
            fechaAsignacion: state.fechaAsignacion,
            fechaImss: state.fechaImss,
            reclutador: state.reclutador,
            contratoLaboralFecha: state.contratoLaboralFecha,
            altaImssFecha: state.altaImssFecha,
            onboardingFecha: state.onboardingFecha,
            facturaFecha: state.facturaFecha
        }));

        await doPost(`/resultadosFinales/asignacion/${vacanteId}/${prospectoId}`, formData, true)
        setDirty(false);
        doFetch(`/resultadosFinales/asignacion/${vacanteId}/${prospectoId}`);
        onComplete()
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={onCancel}>
                Asignación
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FlexColumn>
                        <FormGroup className="NoFlex">
                            <h6>Empresa/Cliente</h6>
                        </FormGroup>
                        <FormGroup>
                            <Label>Nombre</Label>
                            <Input type="text"
                                value={state.empresa}
                                onChange={handleChange('empresa')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Domicilio</Label>
                            <Input type="text"
                                value={state.domicilio}
                                onChange={handleChange('domicilio')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>RFC</Label>
                            <Input type="text"
                                value={state.rfc}
                                onChange={handleChange('rfc')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Registro Patronal</Label>
                            <Input type="text"
                                value={state.registroPatronal}
                                onChange={handleChange('registroPatronal')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Contacto que atiende la asignación</Label>
                            <Input type="text"
                                value={state.contacto}
                                onChange={handleChange('contacto')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Puesto contacto</Label>
                            <Input type="text"
                                value={state.puestoContacto}
                                onChange={handleChange('puestoContacto')}
                                required />
                        </FormGroup>
                        <br />
                        <FormGroup className="NoFlex">
                            <h6>Colaborador/Prospecto</h6>
                        </FormGroup>
                        <FormGroup>
                            <Label>Nombre</Label>
                            <Input type="text"
                                value={state.colaborador}
                                onChange={handleChange('colaborador')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Puesto asignado</Label>
                            <Input type="text"
                                value={state.puestoAsignado}
                                onChange={handleChange('puestoAsignado')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Sueldo mensual</Label>
                            <Input type="text"
                                value={state.sueldo}
                                onChange={handleChange('sueldo')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Fecha de aisgnación/Inicio de labores</Label>
                            <Input type="date"
                                value={state.fechaAsignacion}
                                onChange={handleChange('fechaAsignacion')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Fecha alta IMSS</Label>
                            <Input type="date"
                                value={state.fechaImss}
                                onChange={handleChange('fechaImss')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Reclutador</Label>
                            <Input type="text"
                                value={state.reclutador}
                                onChange={handleChange('reclutador')}
                                required />
                        </FormGroup>
                        <br />
                        <FormGroup className="NoFlex">
                            <h6>DOCUMENTOS DE INGRESO</h6>
                        </FormGroup>
                        <FormGroup>
                            <Label>Contrato Laboral</Label>
                            <Input type="date"
                                value={state.contratoLaboralFecha}
                                onChange={handleChange('contratoLaboralFecha')}
                                required />
                            <FileInput maxSize={10}
                                fileId="contratoLaboral"
                                onChange={handleFileChange('contratoLaboral')}></FileInput>
                            <div className="FontRed">{error}</div>
                            {
                                files.contratoLaboral && typeof files.contratoLaboral === 'string' ?
                                    <FormGroup>
                                        <DownloadFileButton link={files.contratoLaboral}>DESCARGAR</DownloadFileButton>
                                    </FormGroup> : null
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label>Alta IMSS</Label>
                            <Input type="date"
                                value={state.altaImssFecha}
                                onChange={handleChange('altaImssFecha')}
                                required />
                            <FileInput maxSize={10}
                                fileId="altaImss"
                                onChange={handleFileChange('altaImss')}></FileInput>
                            <div className="FontRed">{error}</div>
                            {
                                files.altaImss && typeof files.altaImss === 'string' ?
                                    <FormGroup>
                                        <DownloadFileButton link={files.altaImss}>DESCARGAR</DownloadFileButton>
                                    </FormGroup> : null
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label>Proceso Onboarding</Label>
                            <Input type="date"
                                value={state.onboardingFecha}
                                onChange={handleChange('onboardingFecha')}
                                required />
                            <FileInput maxSize={10}
                                fileId="onboarding"
                                onChange={handleFileChange('onboarding')}></FileInput>
                            <div className="FontRed">{error}</div>
                            {
                                files.onboarding && typeof files.onboarding === 'string' ?
                                    <FormGroup>
                                        <DownloadFileButton link={files.onboarding}>DESCARGAR</DownloadFileButton>
                                    </FormGroup> : null
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label>Factura electrónica</Label>
                            <Input type="date"
                                value={state.facturaFecha}
                                onChange={handleChange('facturaFecha')}
                                required />
                            <FileInput maxSize={10}
                                fileId="factura"
                                onChange={handleFileChange('factura')}></FileInput>
                            <div className="FontRed">{error}</div>
                            {
                                files.factura && typeof files.factura === 'string' ?
                                    <FormGroup>
                                        <DownloadFileButton link={files.factura}>DESCARGAR</DownloadFileButton>
                                    </FormGroup> : null
                            }
                        </FormGroup>
                        <FlexRow justifyContent='flex-end' className="FormButtons">
                            <Button type="submit"
                                disabled={working}
                                color="primary">Guardar</Button>
                            <Button color="secondary"
                                disabled={working}
                                onClick={onCancel}>Cancelar</Button>
                        </FlexRow>
                    </FlexColumn>
                </Form>
            </ModalBody>
        </Modal>
    )

}