import React, {useState, useEffect} from 'react';
import { FormGroup, Input, Modal, Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import HttpRequest from '../shared/HttpRequest'
import FlexColumn from '../shared/FlexColumn'
import useToggle from '../hooks/useToggle'

export default function SelectFolderProspecto({prospectoId, vacanteId}) {

    const [status, setStatus] = useState('');
    const [showModal, toggleShowModal] = useToggle(false)
    const [newStatus, setNewStatus] = useState('');

    useEffect(() => {
      async function fetchProspectoStatus() {
        const response = await HttpRequest.get(`/prospectos/prospecto/${vacanteId}/${prospectoId}/status`);
        setStatus(response.data.status);
      }

      fetchProspectoStatus();
    }, [prospectoId, vacanteId])

    async function confirmChange(event) {
      toggleShowModal();
      setStatus(newStatus);
      setNewStatus('');
      await HttpRequest.post(`/prospectos/prospecto/${vacanteId}/${prospectoId}/status`, {
        status:newStatus
      }); 
    }

    async function handleChange(event) {
      const newValue = event.target.value;
      setNewStatus(newValue);    
      toggleShowModal();       
    }
    
    return (
      <FormGroup className="NoFlex">
          <Input type="select" style={{width:'200px'}} 
            value={status}
            onChange={handleChange}>
              <option value="participante">Participantes</option>
              <option value="finalista">Finalistas</option>
              <option value="descartado">Descartados</option>
          </Input>
          <Modal isOpen={showModal}>
                <ModalHeader toggle={toggleShowModal}>
                    Cambiar a prospecto de carpeta
                </ModalHeader>
                <ModalBody>
                    <p>
                      Cambiar al prospecto de <span className="Bold">{status.toUpperCase()}</span> a <span className="Bold">{newStatus.toUpperCase()}</span>
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={confirmChange} color="primary">Aceptar</Button>
                    <Button color="secondary" onClick={toggleShowModal}>Cancelar</Button>
                </ModalFooter>
          </Modal>
      </FormGroup>      
    )
}