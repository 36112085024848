import React, { useState, useEffect } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup,
    Label, Input, Table
} from 'reactstrap';
import FlexRow from '../shared/FlexRow';
import FlexColumn from '../shared/FlexColumn';
import usePostHttpRequest from '../hooks/usePostHttpRequest';
import Checkbox from '../shared/Checkbox';
import useFetch from '../hooks/useFetch';

export default function AddOnboardingModal({ prospectoId, vacanteId, isOpen, onComplete, onCancel }) {

    const [state, setState] = useState({
        empresa: '',
        fecha: '',
        departamento: '',
        puesto: '',
        colaborador: '',
        reclutador: '',
        contrato: 'Tiempo Determinado',
        contratoOtro: '',
        horario: '',
        salario: '',
        cubiculo: 'Privado'
    })

    const [working, doPost] = usePostHttpRequest();
    const [fetching, response, doFetch] = useFetch(null)
    const [error, setError] = useState('');
    const [dirty, setDirty] = useState(false);

    const herramientasOptions = [
        'Escritorio', 'Computadora', 'Celular', 'Teléfono Fijo', 'Vehículo', 'Motocicleta', 'Tablet',
        'Correo Electrónico', 'Tarjetas de Presentación', 'Vales de gasolina y/o códigos', 'Uniforme de trabajo',
        'Equipo de seguridad', 'Otro'
    ]

    const [herramientas, setHerramientas] = useState(herramientasOptions.map(h => ({
        checked: false,
        nombre: h,
        descripcion: ''
    })))

    const contactosOptions = [
        'Listado de proveedores', 'Listado de clientes', 'Listado de cuentas por cobrar', 'Expediente clientes',
        'Acceso a cuentas bancarias', 'Plan de trabajo', 'Directorio tel. de la empresa', 'Email de colaboradores',
        'Claves de acceso', 'Otros'
    ]

    const [contactos, setContactos] = useState(contactosOptions.map(h => ({
        checked: false,
        nombre: h,
        descripcion: ''
    })))

    useEffect(() => {
        if (vacanteId && prospectoId) {
            doFetch(`/resultadosFinales/onboarding/${vacanteId}/${prospectoId}`);
        }
    }, [vacanteId, prospectoId])

    useEffect(() => {
        if (response) {
            setHerramientas(response.herramientas)
            setContactos(response.contactos)
            setState(response);
        }
    }, [response])

    function handleChange(name) {
        return function (event) {
            setState({ ...state, [name]: event.target.value })
        }
    }

    function handleChangeDesc(name, i, value) {
        const newValue = herramientas.slice();
        newValue[i][name] = value;
        console.log(newValue)
        setHerramientas(newValue)
    }

    function handleCheckboxChange(name, i, checked) {
        const newValue = herramientas.slice();
        newValue[i][name] = checked;
        console.log(newValue)
        setHerramientas(newValue)
    }

    function handleChangeDescContacto(name, i, value) {
        const newValue = contactos.slice();
        newValue[i][name] = value;
        setContactos(newValue)
    }

    function handleCheckboxChangeContacto(name, i, checked) {
        const newValue = contactos.slice();
        newValue[i][name] = checked;
        setContactos(newValue)
    }


    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        const formData = new FormData();
        formData.append('onboarding', JSON.stringify({
            empresa: state.empresa,
            fecha: state.fecha,
            departamento: state.departamento,
            puesto: state.puesto,
            colaborador: state.colaborador,
            reclutador: state.reclutador,
            contrato: state.contrato,
            contratoOtro: state.contratoOtro,
            horario: state.horario,
            cubiculo: state.cubiculo
        }))
        formData.append('herramientas', JSON.stringify(herramientas))
        formData.append('contactos', JSON.stringify(contactos));

        await doPost(`/resultadosFinales/onboarding/${vacanteId}/${prospectoId}`, formData, true)
        setDirty(false);
        doFetch(`/resultadosFinales/onboarding/${vacanteId}/${prospectoId}`);
        onComplete()
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={onCancel}>
                Onboarding
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FlexColumn>
                        <FormGroup>
                            <Label>Empresa</Label>
                            <Input type="text"
                                value={state.empresa}
                                onChange={handleChange('empresa')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Fecha</Label>
                            <Input type="date"
                                value={state.fecha}
                                onChange={handleChange('fecha')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Departamento</Label>
                            <Input type="text"
                                value={state.departamento}
                                onChange={handleChange('departamento')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Puesto</Label>
                            <Input type="text"
                                value={state.puesto}
                                onChange={handleChange('puesto')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Colaborador(a)</Label>
                            <Input type="text"
                                value={state.colaborador}
                                onChange={handleChange('colaborador')}
                                required />
                        </FormGroup>
                        <FormGroup>
                            <Label>Reclotador(a)</Label>
                            <Input type="text"
                                value={state.reclutador}
                                onChange={handleChange('reclutador')}
                                required />
                        </FormGroup>
                        <FormGroup className="NoFlex">
                            <Label>Contrato</Label>
                            <Input type="select"
                                value={state.contrato}
                                onChange={handleChange('contrato')}>
                                <option>Tiempo determinado</option>
                                <option>Obra determinada</option>
                                <option>Tiempo Indeterminado</option>
                                <option>Capacitación</option>
                                <option>Otro (Especificar)</option>
                            </Input>
                        </FormGroup>
                        <FormGroup className="NoFlex">
                            {
                                state.contrato === 'Otro (Especificar)' ?
                                    <Input type="text"
                                        value={state.contratoOtro}
                                        onChange={handleChange('contratoOtro')}>
                                    </Input>
                                    : ''
                            }
                        </FormGroup>
                        <FormGroup>
                            <Label>Horario</Label>
                            <Input type="text"
                                value={state.horario}
                                onChange={handleChange('horario')}
                                required />
                        </FormGroup>
                        <Label>Oficina (Cubículo)</Label>
                        <FormGroup className="NoFlex">
                            <Input type="select"
                                value={state.cubiculo}
                                onChange={handleChange('cubiculo')}>
                                <option>Privado</option>
                                <option>Compartido</option>
                            </Input>
                        </FormGroup>
                        <br />
                        <FormGroup className="NoFlex">
                            <h6>Herramientas de Trabajo</h6>
                            <Table className="HerramientasTable">
                                <thead>
                                    <tr>
                                        <th>Herramienta</th>
                                        <th>Descripción de la herramienta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        herramientas.map((h, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <FormGroup>
                                                        <Checkbox
                                                            checked={h.checked}
                                                            onChange={(e) => handleCheckboxChange('checked', i, e.target.checked)}>{h.nombre}</Checkbox>
                                                    </FormGroup>
                                                </td>
                                                <td>
                                                    <FormGroup>
                                                        <Input placeholder="Descripción" type="text"
                                                            value={h.descripcion}
                                                            onChange={(e) => handleChangeDesc('descripcion', i, e.target.value)}></Input>
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </FormGroup>
                        <br />
                        <FormGroup className="NoFlex">
                            <h6>Contactos/Administrativo</h6>
                            <Table className="HerramientasTable">
                                <thead>
                                    <tr>
                                        <th>Utilitarios</th>
                                        <th>Observaciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        contactos.map((h, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <FormGroup>
                                                        <Checkbox
                                                            checked={h.checked}
                                                            onChange={(e) => handleCheckboxChangeContacto('checked', i, e.target.checked)}>{h.nombre}</Checkbox>
                                                    </FormGroup>
                                                </td>
                                                <td>
                                                    <FormGroup>
                                                        <Input placeholder="Descripción" type="text"
                                                            value={h.descripcion}
                                                            onChange={(e) => handleChangeDescContacto('descripcion', i, e.target.value)}></Input>
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </FormGroup>
                        <FlexRow justifyContent='flex-end' className="FormButtons">
                            <Button type="submit"
                                disabled={working}
                                color="primary">Guardar</Button>
                            <Button color="secondary"
                                disabled={working}
                                onClick={onCancel}>Cancelar</Button>
                        </FlexRow>
                    </FlexColumn>
                </Form>
            </ModalBody>
        </Modal>
    )

}