import React, {useState, useEffect} from 'react';
import HttpRequest from '../shared/HttpRequest';

export default function useReclutadores() {

    const [reclutadores, setReclutadores] = useState([])

    useEffect(() => {
        async function fetchReclutadores() {
            const response = await HttpRequest.get('/reclutadores');
            setReclutadores(response.data)
        }
        fetchReclutadores();
    }, [])

    return reclutadores;

}