import React from 'react';

function SectionHeader({children, subtitle, textAlign}) {
    return (
        <div style={{marginTop:'40px', textAlign: textAlign || 'inherit'}}>            
            <h4>{children}</h4>
            {
                subtitle ? <h5>{subtitle}</h5> : null
            }
            <hr/>
        </div>
    )
}

export default SectionHeader;