import React, {useEffect} from 'react';
import useFetch from '../hooks/useFetch';
import {Button} from 'reactstrap';
import FileSaver from 'file-saver';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import BorderButton from './BorderButton';

export default function SaveAsButton({link, title, iconButton, children, ...rest}) {

    const [fetching, data, doFetch] = useFetch(null)

    function handleClick(event) {
        event.preventDefault();
        event.stopPropagation();        
        doFetch(link, {
            responseType: 'blob'
        });
    }

    useEffect(() => {
        if(data) {
            FileSaver.saveAs(data, title);
        }
    }, [data, title])

    return (
        iconButton ? 
        <BorderButton disabled={fetching} onClick={handleClick} icon={faCloudDownloadAlt} {...rest}>
            {children}
        </BorderButton>
        :
        <Button disabled={fetching} onClick={handleClick} className='btn-blue' {...rest}>
            {children}
        </Button>
    )

}