import {useEffect} from 'react';
import {withRouter} from 'react-router-dom';

export default withRouter(function RouteScrollToTop({history}) {
    
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0,0);
        })
        return () => unlisten()
    }, [])

    return null
});