import React, {useState} from 'react';
import HttpRequest from '../shared/HttpRequest';

export default function usePostHttpRequest() {
    const [working, setWorking] = useState(false);
    const [response, setResponse] = useState(null)

    async function doPost(path, body, multipart) {
        setWorking(true);
        let result = null;
        if(multipart) {
            result = await HttpRequest.postMultipart(path, body)
        } else {
            result = await HttpRequest.post(path, body)
        }        
        setResponse(result);
        setWorking(false);
    }

    return [working, doPost, response];
    
}