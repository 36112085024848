import React from 'react';
import './App.css';
import Main from './Main'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './Login';
import PrivateRoute from './shared/PrivateRoute';
import RouteScrollToTop from './shared/RouteScrollToTop'

function App() {
  return (
    <Router>
      <div className="App">      
        <RouteScrollToTop></RouteScrollToTop>
        <Switch>
          <Route exact path="/login" component={Login}></Route>                      
          <PrivateRoute path="/" component={Main}></PrivateRoute>
        </Switch>        
      </div>
    </Router>
  );
}

export default App;
