import React, { useEffect, useState } from 'react';
import HttpRequest from './HttpRequest';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import useToggle from '../hooks/useToggle';

const path = '/file/sign/video/';

export function ShowVideoButton({link, children, ...props}) {

    const [isModalOpen, toggleModal] = useToggle(false)


    return (
        <React.Fragment>
            <Button className="btn-blue" {...props}
                onClick={toggleModal}>{children}</Button>
            <VideoModal link={link}
                isOpen={isModalOpen}
                onClose={toggleModal}></VideoModal>
        </React.Fragment>
    )
}

export function VideoModal({link, isOpen, onClose}) {    

    const [signedLink, setSignedLink] = useState(null)

    useEffect(() => {

        async function fetchSignedLink() {
            const response = await HttpRequest.get(path + link);
            setSignedLink(`${HttpRequest.backend}/file/signed/video/${response.data.signed}`)
        }

        if(isOpen && link) {
            fetchSignedLink();
        } else if(!isOpen) {
            setSignedLink(null)
        }

    }, [isOpen, link])

    return (
        <Modal isOpen={!!(isOpen && signedLink)}>
            <ModalHeader toggle={onClose}></ModalHeader>
            <ModalBody>
                <video controls controlsList="nodownload" style={{
                    width:'100%'
                }}>
                    <source src={signedLink}
                        type="video/mp4"/>
                    Sorry, your browser doesn't support embedded videos.
                </video>
            </ModalBody>
        </Modal>
    )


}
