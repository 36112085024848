import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function Fab({onClick}) {
    return (
        <div className="Fab" onClick={onClick}>
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
        </div>
    )
}

export default Fab