import React, {useState} from 'react';
import FlexColumn from './FlexColumn';
import withFilterOptions from './withFilterOptions';
import {Button, Input, FormGroup, Label} from 'reactstrap'
import {withRouter} from 'react-router-dom'
import HttpRequest from './HttpRequest'
import Checkbox from './Checkbox';
import useSession from '../hooks/useSession';


const FilterEmpresas = withFilterOptions('Empresa', () => HttpRequest.get('/buscar/requisiciones/empresas'))
const FilterSucursales = withFilterOptions('Sucursal', () => HttpRequest.get('/buscar/requisiciones/sucursales'))
const FilterPuestos = withFilterOptions('Vacante', () => HttpRequest.get('/buscar/requisiciones/vacantes'))
const FilterStatus = withFilterOptions('Estatus', () => HttpRequest.get('/buscar/vacantes/status'))

function FilterVacantes({history}) {

    const [userData, login, logout] = useSession();

    const [filters, setFilters] = useState({
        empresa: 'any',
        sucursal: 'any',
        vacante: 'any',
        status: 'any',
        archivados: false
    })

    function handleChange(name) {
        return function(event) {
            const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
            setFilters({...filters, [name]:value})
        }
    }

    function handleClickApply() {
        let query = Object.keys(filters).reduce((pv, cv, i) => { 
            return `${pv}${i > 0 ? '&':''}${cv}=${filters[cv]}`
        }, '?')
        history.push('/vacantes' + query)
    }

    return (
        <FlexColumn>
            {userData.admin ? 
            <>
                <FilterEmpresas value={filters.empresa} onChange={handleChange('empresa')}></FilterEmpresas>
                <FilterSucursales value={filters.sucursal} onChange={handleChange('sucursal')}></FilterSucursales>
                <FilterPuestos value={filters.vacante} onChange={handleChange('vacante')}></FilterPuestos>
            </> : ''}
            <FilterStatus value={filters.status} onChange={handleChange('status')}></FilterStatus>
            <br/>
            <Checkbox
                value={filters.archivados}
                onChange={handleChange('archivados')}>Archivados</Checkbox>
            <Button style={{width:'100%'}} className="btn-gray" 
            onClick={handleClickApply}>APLICAR</Button>
        </FlexColumn>
    )

}

export default withRouter(FilterVacantes)