import React, {useState} from 'react';
import FlexRow from '../shared/FlexRow';
import {  FormGroup, Label, Input } from 'reactstrap';
import FastInput from '../shared/FastInput';
import styles from './RequisicionForm.module.css'


export default function RequisicionOfertaLaboral({value, onChange, showDirty}) {

    const [dirty, setDirty] = useState(false);

    function handleChange(name) {
        return function(event) {
            setDirty(true);
            onChange({...value, [name]:event.target.value})
        }
    }   

    return (
        <div className={`${dirty && showDirty ? styles.sectionDirty : ''}`}>
            <h6>Oferta Laboral</h6>
            <FlexRow>
                <FormGroup>
                    <Label>Sueldo mensual</Label>
                    <FastInput placeholder="" type="text" 
                        value={value.sueldo}
                        onChange={handleChange('sueldo')}
                        required></FastInput>
                </FormGroup>
                <FormGroup>
                    <FormGroup check>
                        <Label check>
                          <Input type="radio" 
                          name="tipoSueldo" 
                          value="Nominales"
                          checked={value.tipoSueldo === 'Nominales'}
                          onChange={handleChange('tipoSueldo')}/>{' '}
                          Nominales
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                          <Input type="radio" 
                          name="tipoSueldo" 
                          value="Libres"
                          checked={value.tipoSueldo === 'Libres'}
                          onChange={handleChange('tipoSueldo')} />{' '}
                          Libres
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                          <Input type="radio" 
                          name="aConvenir" 
                          value="A convenir"
                          checked={value.tipoSueldo === 'A convenir'}
                          onChange={handleChange('tipoSueldo')} />{' '}
                          A convenir
                        </Label>
                    </FormGroup>
                </FormGroup>
            </FlexRow>
            <FormGroup>
                <Label>Período de pago</Label>
                <FastInput placeholder="" type="select" 
                    value={value.periodoPago}
                    onChange={handleChange('periodoPago')}
                    required>
                    <option>Semanal</option>
                    <option>Quincenal</option>
                    <option>Mensual</option>
                </FastInput>
            </FormGroup>
            </div>
    )

}