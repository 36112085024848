import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label} from 'reactstrap';
import FlexColumn from '../shared/FlexColumn';
import FlexRow from '../shared/FlexRow';
import FastInput from '../shared/FastInput';

export default function DatosGeneralesFormDireccion({value, onChange}) {

    function handleChange(name) {
        return function(event) {
            onChange({...value, [name]:event.target.value})
        }
    }


    return (
        <React.Fragment>
            <h6>Dirección</h6>
            <FormGroup>
                <Label>Calle</Label>
                <FastInput type="text" 
                    value={value.calle}
                    onChange={handleChange('calle')}
                    required/>
            </FormGroup>
            <FormGroup>
                <Label>Número</Label>
                <FastInput type="text" 
                    value={value.numero}
                    onChange={handleChange('numero')}
                    required/>
            </FormGroup>
            <FormGroup>
                <Label>Colonia</Label>
                <FastInput type="text" 
                    value={value.colonia}
                    onChange={handleChange('colonia')}
                    required/>
            </FormGroup>
            <FormGroup>
                <Label>Estado</Label>
                <FastInput type="text" 
                    value={value.estado}
                    onChange={handleChange('estado')}
                    required/>
            </FormGroup>
            <FormGroup>
                <Label>Delegación o Municipio</Label>
                <FastInput type="text" 
                    value={value.municipio}
                    onChange={handleChange('municipio')}
                    required/>
            </FormGroup>
        </React.Fragment>
    )

}