import React, {useState} from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import FlexRow from '../shared/FlexRow'
import FlexColumn from '../shared/FlexColumn';
import IconButton from '../shared/IconButton';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faArchive } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import HttpRequest from '../shared/HttpRequest';

export default function ArchivarModal({modal, onCancel, onArchived}) {
    
    const [working, setWorking] = useState(false);

    async function handleClickArchivar() {
        setWorking(true);
        try {
            await HttpRequest.post(`/requisiciones/${modal.requisicionId}/archivar`, {})
            setWorking(false);
            onArchived()
        } catch(e) {
            console.log(e);
            setWorking(false);
        }
    }

    return (
        <Modal isOpen={modal.open}>
            <ModalBody>
                <FlexRow justifyContent="flex-end">
                    <div className="FontGray">
                       {
                           !working ? <IconButton icon={faTimesCircle} onClick={onCancel}></IconButton> : null
                       }
                    </div>
                </FlexRow>
                <FlexColumn className="ModalContent Center" justifyContent="center">                
                    <FlexRow>
                        <FontAwesomeIcon className={'IconButton FontBlue'} style={{fontSize:'30px'}} icon={faArchive}></FontAwesomeIcon>
                        <div className="FontBlue Bold" style={{marginLeft:'10px'}}>Archivar proceso</div>
                    </FlexRow>
                    <br/>
                    <p>
                        Una vez que archivas el proceso de reclutamiento, se quitará del listado principal
                        y ya no podrás modificarlo. ¿Deseas continuar?
                    </p>
                </FlexColumn>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleClickArchivar} color="primary" disabled={working}>Aceptar</Button>
                <Button color="secondary" onClick={onCancel} disabled={working}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )

}