
import React, {useState, useEffect} from 'react';
import { Button } from 'reactstrap';
import FlexRow from './FlexRow';
import FlexColumn from './FlexColumn';
import DownloadFileButton from './DownloadFileButton';
import HttpRequest from './HttpRequest'

export default function FileInput({maxSize, onChange, accept, fileId, fileUrl, working, filePath}) {
    
    const [fileName, setFileName] = useState('Ningún archivo seleccionado');
    const [error, setError] = useState(null);

    function handleChange(event) {
        
        if(!event.target.files || !event.target.files[0]) {
            return;
        }

        const file = event.target.files[0];
        //tamaño en mb
        const size = (file.size/1024/1024).toFixed(4);
        if(size > maxSize) {
            setFileName('')
            setError(`El archivo es demasiado grande, el tamaño máximo es de ${maxSize} MB`)
            return;
        }

        setError(null);
        setFileName(event.target.files[0].name);
        onChange(event.target.files[0]);
    }

    return (
        <FlexColumn>
            <FlexRow>
                {
                    working ?
                    <div>Cargando...</div> :
                    <React.Fragment>
                        <input
                        accept={accept}
                        type="file"                            
                        onChange={handleChange}
                        style={{display:'none'}}
                        id={fileId}
                        />
                        <label htmlFor={fileId} style={{marginBottom:0}}>
                            <span className="btn btn-secondary"
                                style={{margin:'5px', cursor:'pointer'}}>Seleccionar archivo (Máximo {maxSize} MB)
                            </span>                    
                        </label>
                        {
                            fileUrl ? 
                            <DownloadFileButton className="btn-white" filePath={filePath} link={fileUrl}>Descargar</DownloadFileButton> :
                            <div>{fileName}</div>
                        }  
                    </React.Fragment>
                }                                              
            </FlexRow>
            {
                error ? <p style={{color:'red'}}>{error}</p> : null
            }            
        </FlexColumn>        
    )

}