import React, {useState} from 'react';
import {  FormGroup, Table } from 'reactstrap';
import FastInput from '../shared/FastInput';
import Checkbox from '../shared/Checkbox';
import styles from './RequisicionForm.module.css'

export default function RequisicionHerramientas({value, onChange, showDirty}) {
    
    const [dirty, setDirty] = useState(false);

    function fireChange(newValue) {
        setDirty(true)
        onChange(newValue);    
    }

    function handleChange(name, i) {
        return function(event) {
            const newValue = value.slice();
            newValue[i][name] = event.target.value;
            fireChange(newValue)
        }
    }   

    function handleCheckboxChange(name, i) {
        return function(event) {
            const newValue = value.slice();
            newValue[i][name] = event.target.checked;
            fireChange(newValue)
        }
    }
    
    return (
        <div className={`${dirty && showDirty ? styles.sectionDirty : ''}`}>
            <h6>Herramientas de Trabajo</h6>
            <p>Selecciona las herramientas de trabajo que se le brindarán al colaborador</p>
            <Table className="HerramientasTable">
                <thead>
                    <tr>
                        <th>Herramienta</th>
                        <th>Privado o compartido</th>
                        <th>Descripción de la herramienta</th>                            
                    </tr>
                </thead>
                <tbody>
                    {
                        value.map((h, i) => (
                            <tr key={i}>
                                <td>
                                    <FormGroup>
                                        <Checkbox 
                                            checked={h.checked}
                                            onChange={handleCheckboxChange('checked', i)}>{h.nombre}</Checkbox>
                                    </FormGroup>
                                </td>
                                <td>
                                    <FormGroup>
                                        <Checkbox
                                            checked={h.privado}
                                            onChange={handleCheckboxChange('privado', i)}>Privado</Checkbox>
                                    </FormGroup>
                                </td>
                                <td>
                                    <FormGroup>
                                        <FastInput placeholder="Descripción" type="text"
                                            value={h.descripcion}
                                            onChange={handleChange('descripcion', i)}></FastInput>
                                    </FormGroup>
                                </td>
                            </tr>
                        ))
                    }                        
                </tbody>
            </Table>
        </div>
    )
}