import React, {useState, useEffect} from 'react';
import SectionHeader from '../shared/SectionHeader';
import FlexRow from '../shared/FlexRow';
import FastInput from '../shared/FastInput';
import {FormGroup, Label, Input, Form, Button} from 'reactstrap'
import HttpRequest from '../shared/HttpRequest';
import CheckboxGroup from '../shared/CheckboxGroup';
import useReclutadores from '../hooks/useReclutadores';
import usePostHttpRequest from '../hooks/usePostHttpRequest';
import Date from '../shared/Date';

const reclutadorOptions = [
    'Médico'/*, 'Entrevista Personal'*/, 'Estudio Socioeconómico'
]


export default function RequisicionConf({match, history}) {

    const [req, setReq] = useState({
        datosGenerales: {
            empresa: '',
            sucursal: ''
        },
        archived: false
    })

    const [conf, setConf] = useState({
        procesoReclutador: [],
        procesoProspecto: [],
        reclutadorId:'',
        tiempoSeleccion:0
    })

    const [savingCierre, doCierre, cierreResponse] = usePostHttpRequest()

    const reclutadores = useReclutadores();
    const [working, setWorking] = useState(false);
    const [isNew, setIsNew] = useState(false);

    useEffect(() => {
        async function fetchConf() {
            const response = await HttpRequest.get(`/requisiciones/${match.params._id}/conf`);
            if(response.data) {
                setConf(response.data)
            } else {
                setIsNew(true)
            }
        }
        fetchConf();
    }, [match.params._id])

    useEffect(() => {    
        async function fetchReq() {
            const response = await HttpRequest.get(`/requisiciones/${match.params._id}/generales`);
            setReq(response.data);
        }    
        fetchReq();
    }, [match.params.id, cierreResponse, match.params._id])    

    function handleChangeBoxGroup(name) {
        return function(data) {
            setConf({...conf, [name]: data})
        }
    }

    function handleChange(name) {
        return function(event) {
            setConf({...conf, [name]:event.target.value})
        }
    }

    function findReclutador() {        
        return reclutadores.find(r => r._id === conf.reclutadorId);        
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        setWorking(true);
        try {
            await HttpRequest.post(`/requisiciones/${match.params._id}/conf`, conf);        
            setWorking(false);
            history.push('/vacantes')
        } catch(e) {
            console.log(e);
            setWorking(false);
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SectionHeader>
                VACANTE {req.datosGenerales.nombrePuesto}
            </SectionHeader>
            <FlexRow>
                <FormGroup>
                    <Label>Empresa</Label>
                    <Input 
                    type="text" 
                    value={req.datosGenerales.empresa}
                    readOnly></Input>
                </FormGroup>
                <FormGroup>
                    <Label>Sucursal</Label>
                    <Input 
                    type="text" 
                    value={req.datosGenerales.sucursal}
                    readOnly></Input>
                </FormGroup>
            </FlexRow>
            {
                req.status !== 'Completada' ?
                <React.Fragment>
                    <SectionHeader>
                        CONFIGURA EL PROCESO DE RECLUTAMIENTO
                    </SectionHeader>
                    <h5>Selecciona aquí el proceso requerido para la vacante</h5>
                    <CheckboxGroup 
                        options={reclutadorOptions} 
                        hideCount={true}                
                        value={conf.procesoReclutador} 
                        disabled={!isNew}
                        onChange={handleChangeBoxGroup('procesoReclutador')}/>
                    <SectionHeader>
                        TIEMPO DE SELECCIÓN
                    </SectionHeader>
                    <FormGroup>
                        <Label>Días</Label>
                        <Input 
                        type="number" 
                        value={conf.tiempoSeleccion}
                        disabled={!isNew}
                        onChange={handleChange('tiempoSeleccion')}
                        min={1}
                        required></Input>
                    </FormGroup>
                    <SectionHeader>
                        ASIGNA ESTA VACANTE AL RECLUTADOR
                    </SectionHeader>
                    <FormGroup>
                        <Label>Asignar a </Label>
                        <Input type="select" 
                            value={conf.reclutadorId}
                            onChange={handleChange('reclutadorId')}
                            required>
                            <option></option>
                            {
                                reclutadores.map(r => (
                                    <option key={r._id} value={r._id}>{r.nombre}</option>
                                ))
                            }
                        </Input>
                    </FormGroup>   
                    <br/>
                    <FlexRow justifyContent="center">
                        <Button className="btn-blue" type="submit" 
                            disabled={working}>GUARDAR</Button>
                    </FlexRow>    
                    <SectionHeader>
                        CERRAR
                    </SectionHeader>    
                    <p>Marcar la requisición como completada</p>            
                    <FlexRow justifyContent="center">
                        <Button className="btn-blue" 
                            onClick={() => doCierre(`/requisiciones/${match.params._id}/complete`)} 
                            disabled={savingCierre}>COMPLETAR</Button>
                    </FlexRow>   
                </React.Fragment> : 
                <React.Fragment>
                    <br/>
                    <p>
                        La requisición ha sido completada el <Date>{req.updatedAt}</Date> por el reclutador {findReclutador().nombre} 
                    </p>
                </React.Fragment>
            }             
        </Form>
    )
}