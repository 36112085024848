import React from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import {CALENDAR_EVENT_TYPES} from './Utils'
import 'moment/locale/es';

moment.locale('es');

const eventTypes = Object.keys(CALENDAR_EVENT_TYPES)

function formatMonth(locale, date) {
    const d = moment(date);
    const month = d.format('MMMM').toUpperCase();
    const year = d.format('YYYY');
    return (
        <div>
            <span className="FontDarkGray Bold">{month}</span> <span className="FontGray Bold" stlye={{fontSize:'12px'}}>{year}</span>
        </div>
    );
}

function getUnrelatedEventColor(eventCount) {
    return eventCount < 2 ? 
        '#c6f1d6' :
        eventCount < 5 ?
        '#ffba92' : 
        '#ff8080'
}

export default function EventsCalendar({prospectoId, vacanteId, onDateChange, value, events, unrelatedEvents, onMonthChange}) {

    function buildTileContent({date, view}) {
        if(view === 'month') {            
            const label = moment(date).format('DD/MM/YYYY')
            if(events[label] && events[label].length > 0) {
                const rejected = events[label].find(e => e.status === 'rejected');
                const backgroundColor = rejected ? 'red' : '#d6d6f3'
                return <div className="CalendarEvent" style={{backgroundColor}}></div>
            }
            if(unrelatedEvents[label] && unrelatedEvents[label].length > 0) {
                const backgroundColor = getUnrelatedEventColor(unrelatedEvents[label].length)
                return <div className="CalendarEvent" style={{backgroundColor}}></div>
            }
        }
        return null;
    }

    function handleDateChange(event) {
        onMonthChange(event.activeStartDate.getMonth())
    }

    return (
        <Calendar  
        tileContent={buildTileContent}
        onChange={onDateChange}
        value={value}
        onActiveDateChange={handleDateChange}
        className="Calendar"       
        prev2Label={null}
        next2Label={null}
        formatMonthYear={formatMonth}
        locale="es-419"></Calendar>
    )
}