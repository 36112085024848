import React, {useState, useEffect} from 'react';
import FlexRow from '../shared/FlexRow';
import {Button} from 'reactstrap';
import HttpRequest from '../shared/HttpRequest';


export default function CalendarAuthorization({isAuthorized, onAuthorizedChanged}) {


    useEffect(() => {
        async function fetchIsAuthorized() {
            const response = await HttpRequest.get('/session/authorizedCalendar');
            onAuthorizedChanged(response.data.authorized);
        }

        fetchIsAuthorized();

    }, [onAuthorizedChanged])

    async function handleAuthorizeClick() {
        const response = await HttpRequest.get('/session/authorizeCalendar');
        window.open(response.data.url)
    }

    return (
        isAuthorized ? null :
        <FlexRow>
            <p style={{padding:'10px'}}>Para poder utilizar las funciones de calendario primero necesitas darnos permiso de acceder a tu calendario de Google</p>
            <Button 
                onClick={handleAuthorizeClick}>Autorizar</Button>
        </FlexRow>
    )

}