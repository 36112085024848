import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label,
    TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import FastInput from '../shared/FastInput';
import FlexColumn from '../shared/FlexColumn';
import FlexRow from '../shared/FlexRow';

export default function DatosGeneraleFormExperienciaLaboral({value, onChange}) {

    const [tab, setTab] = useState(0)

    function handleChange(name, i) {
        return function(event) {
            const newValue = value.slice();
            newValue[i][name] = event.target.value;
            onChange(newValue)
        }
    }

    function removeFromArray(index) {
        return function(event) {
            onChange(value.filter((v, i) => i != index))
            if(tab === index) {
                setTab(0)
            }
        }
    }

    function addToArray() {
        onChange([...value, {
            empresa: '',
            puesto: '',
            fechaInicio: new Date(),
            fechaTermino: new Date(),
            actividadesClave: '',
            logros: ''
        }])
        setTab(value.length)
    }

    return (
        <React.Fragment>
            <h6>Experiencia Laboral</h6>
            <Nav tabs>
                {
                    value.map((e, i) => (
                        <NavItem key={i}>
                            <NavLink
                            onClick={() => setTab(i)}
                            className={tab == i ? 'active' : ''}>{i + 1}</NavLink>
                        </NavItem>
                    ))
                }                
                {/* <NavItem>
                    <NavLink
                        onClick={addToArray}
                    >Agregar</NavLink>
                </NavItem> */}
            </Nav>
            <TabContent activeTab={tab}>
                {
                    value.map((e,i) => (
                        <TabPane tabId={i} key={i}>
                            {
                                value.length > 1 ?
                                <Button onClick={removeFromArray(i)}>Eliminar</Button> : null
                            }
                            <FormGroup>
                                <Label>Empresa</Label>
                                <FastInput type="text" 
                                    value={e.empresa}
                                    onChange={handleChange('empresa', i)}
                                    required/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Puesto desempeñado</Label>
                                <FastInput type="text" 
                                    value={e.puesto}
                                    onChange={handleChange('puesto', i)}
                                    required/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Fecha de inicio</Label>
                                <FastInput type="date" 
                                    value={e.fechaInicio}
                                    onChange={handleChange('fechaInicio', i)}
                                    required/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Fecha de término</Label>
                                <FastInput type="date" 
                                    value={e.fechaTermino}
                                    onChange={handleChange('fechaTermino', i)}
                                    required/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Actividades clave</Label>
                                <FastInput type="textarea" 
                                    value={e.actividadesClave}
                                    onChange={handleChange('actividadesClave', i)}
                                    required/>
                            </FormGroup>
                            <FormGroup>
                                <Label>Logros</Label>
                                <FastInput type="textarea" 
                                    value={e.logros}
                                    onChange={handleChange('logros', i)}
                                    required/>
                            </FormGroup>
                        </TabPane>
                    ))
                }
            </TabContent>
        </React.Fragment>
    )   
}