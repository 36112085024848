import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label} from 'reactstrap';
import FastInput from '../shared/FastInput';
import FlexColumn from '../shared/FlexColumn';
import FlexRow from '../shared/FlexRow';

export default function DatosGeneralesFormExtra({value, onChange}) {

    function handleChange(name) {
        return function(event) {
            onChange({...value, [name]:event.target.value})
        }
    }

    return (
        <React.Fragment>
            <FormGroup>
                <Label>Competencias Laborales</Label>
                <FastInput type="textarea" 
                    value={value.competenciasLaborales}
                    onChange={handleChange('competenciasLaborales')}
                    required/>
            </FormGroup>
            <FormGroup>
                <Label>Áreas de interés</Label>
                <FastInput type="textarea" 
                    value={value.areasInteres}
                    onChange={handleChange('areasInteres')}
                    required/>
            </FormGroup>            
        </React.Fragment>
    )

}