import React from 'react';
import FlexRow from '../shared/FlexRow'
import FlexColumn from '../shared/FlexColumn';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import styles from './ListVacanteItem.module.css';
import SaveAsButton from '../shared/SaveAsButton';
import BorderButton from '../shared/BorderButton';
import ReqHistory from './ReqHistory';

export default function ListRequisicionItem({requisicion, onEditClick, onClick}) {    
    
    const datosGenerales = requisicion.datosGenerales;

    if(!requisicion.history) {
        requisicion.history = [];
    }

    function handleEditClick(event) {
        event.stopPropagation();
        onEditClick(requisicion._id);
    }

    function handleClick() {
        // onClick(vacante._id)
    }

    return (
        <FlexColumn className={styles.container} onClick={handleClick}>
            <FlexRow className={styles.containerRow}>
                <FlexColumn className={styles.leftColumn}>
                    <FlexRow>
                        <div className="Bold">{datosGenerales.empresa}</div>
                        <div className="Bold">{datosGenerales.sucursal}</div>
                        <div className="Bold">{datosGenerales.nombrePuesto}</div>
                    </FlexRow>
                </FlexColumn>
                <FlexColumn className={styles.rightColumn}>
                    <div className="Bold">{datosGenerales.fecha}</div>
                </FlexColumn>
            </FlexRow>
            <FlexRow className={styles.containerRow}>
                <FlexColumn className={styles.leftColumn}>
                    <FlexRow>
                        <div className="Bold" style={{flexGrow:0, marginRight:'10px'}}>Estatus:</div>
                        <div>Nueva</div>
                    </FlexRow>                    
                </FlexColumn>
                <FlexColumn className={styles.rightColumn}>
                <BorderButton icon={faPencilAlt} 
                    onClick={handleEditClick}>CONFIGURAR</BorderButton>
                </FlexColumn>
            </FlexRow>
            <FlexRow className={styles.containerRow}>
                <FlexColumn>
                    <FlexRow>
                        <span>Descargar requisición</span>
                        <SaveAsButton
                            style={{marginLeft:'10px'}}
                            iconButton={true}
                            link={`/requisiciones/${requisicion._id}/${requisicion.history.length - 1}`}
                            title="requisicion.pdf">DESCARGAR PDF</SaveAsButton>
                    </FlexRow>                    
                </FlexColumn>
                <FlexColumn className={styles.rightColumn}>
                </FlexColumn>
            </FlexRow>
            <ReqHistory requisicion={requisicion}></ReqHistory>
        </FlexColumn>
    )

}