import React, {useState} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup,
Label, Input } from 'reactstrap';
import FlexRow from '../shared/FlexRow';
import FlexColumn from '../shared/FlexColumn';
import FileInput from '../shared/FileInput'
import usePostHttpRequest from '../hooks/usePostHttpRequest';

export default function AddPostAsignacionModal({postAsignacionId, isOpen, onComplete, onCancel}) {

    const [state, setState] = useState({
        name:'',
        documento:null
    }) 
    const [file, setFile] = useState(null)
    const [working, doPost] = usePostHttpRequest();
    const [error, setError] = useState('');

    function handleChange(name) {
        return function(event) {
            setState({...state, [name]:event.target.value})
        }
    }

    function handleFileChange(file) {
        setFile(file)
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        if(!file) {
            setError('Todos los campos son obligatorios')
            return;
        }
        const formData = new FormData();
        formData.append('documento', file);
        formData.append('postAsignacion', JSON.stringify({
            name: state.name
        }));

        await doPost(`/postasignaciones/${postAsignacionId}`, formData, true)
        onComplete()
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>
                Agregar Post Asignación
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FlexColumn>
                         <FormGroup>
                            <Label>Nombre</Label>
                            <Input type="text" 
                                value={state.name}
                                onChange={handleChange('name')}
                                required/>
                        </FormGroup>
                        <br/>
                        <FileInput maxSize={10} 
                            fileId="documento"
                            fileUrl={state.documento}
                            onChange={handleFileChange}></FileInput>
                        <div className="FontRed">{error}</div>
                        <FlexRow justifyContent='flex-end' className="FormButtons">
                            <Button type="submit" 
                                disabled={working}
                                color="primary">Guardar</Button>
                            <Button color="secondary" 
                                disabled={working}
                                onClick={onCancel}>Cancelar</Button>
                        </FlexRow>                        
                    </FlexColumn>
                </Form>
            </ModalBody>
        </Modal>
    )

}