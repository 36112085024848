import React, {useState, useEffect} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input} from 'reactstrap';
import FastInput from '../shared/FastInput';
import FlexColumn from '../shared/FlexColumn';
import FlexRow from '../shared/FlexRow';
import Checkbox from '../shared/Checkbox';
import HttpRequest from '../shared/HttpRequest';
import {formatDate} from '../shared/Utils'


export default function EntrevistaTelefonicaFormFields({vacanteId, prospectoId, puesto, onComplete, onCancel, isOpen}) {

    const [entrevista, setEntrevista] = useState({
        fecha: formatDate(new Date()),
        autorizaEntrevista: false,
        autorizaGrabar: false,
        personal: '',
        razonInteresado: '',
        trayectoria: '',
        empleoAnterior:'',
        razonTerminoEmpleoAnterior: '',
        antiguedadAnterior:'',
        sueldoAnterior:''
    })
    const [working, setWorking] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const response = await HttpRequest.get(`/prospectos/prospecto/${vacanteId}/${prospectoId}/proceso/entrevistaTelefonica`);
            const {result, data} = response.data;
            if(result === 'ok') {
                const e = data.datos;
                e.fecha = formatDate(data.datos.fecha);
                setEntrevista(e);
            }
        }
        if(isOpen) {
            console.log('fetching telefonica: ' +  prospectoId)
            fetchData();
        }     
    }, [prospectoId, isOpen])

    function handleChange(name) {
        return function(event) {
            const target = event.target;
            setEntrevista({...entrevista, [name]: target.type === 'checkbox' ? target.checked : target.value})
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        setWorking(true);
        const formData = new FormData();
        formData.append('stepData', JSON.stringify({
            stepName:'entrevistaTelefonica',
            stepData: entrevista
        }));
        const response = await HttpRequest.postMultipart(`/prospectos/prospecto/${vacanteId}/${prospectoId}/proceso`, formData)        
        setWorking(false);
        onComplete(response.data);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FlexColumn>
                <FormGroup>
                    <Label>Fecha</Label>
                    <FastInput type="date" 
                        value={entrevista.fecha}
                        onChange={handleChange('fecha')}
                        required/>
                </FormGroup>
                <p>
                    Le llamamos del área de reclutamiento de la empresa Integra RH, 
                    hemos recibido su documentación para el puesto de: {puesto}
                </p>
                <p>
                    La intención de nuestra llamada es llevar a cabo una entrevista telefónica con 
                    fines de conocer sobre su trayectoria personal y profesional.
                </p>
                <Checkbox checked={entrevista.autorizaEntrevista}
                    onChange={handleChange('autorizaEntrevista')}>
                    ¿Autoriza usted que se lleve a cabo la entrevista?
                </Checkbox>
                <Checkbox checked={entrevista.autorizaGrabar}
                    onChange={handleChange('autorizaGrabar')}>
                    Es importante comentar que la siguiente llamada puede ser grabada con fines de mejorar la 
                    calidad de nuestros servicios, ¿está usted de acuerdo? 
                </Checkbox>
                {
                    entrevista.autorizaEntrevista && entrevista.autorizaGrabar ?
                    <React.Fragment>
                        <FormGroup>
                            <Label>Hábleme usted de en lo personal</Label>
                            <FastInput type="textarea" 
                                value={entrevista.personal}
                                onChange={handleChange('personal')}
                                required/>
                        </FormGroup>
                        <FormGroup>
                            <Label>¿Porqué le interesa la vacante?</Label>
                            <FastInput type="textarea" 
                                value={entrevista.razonInteresado}
                                onChange={handleChange('razonInteresado')}
                                required/>
                        </FormGroup>
                        <FormGroup>
                            <Label>¿Podrá compartirnos en un lapso de 3 minutos su trayectoria profesional?</Label>
                            <FastInput type="textarea" 
                                value={entrevista.trayectoria}
                                onChange={handleChange('trayectoria')}
                                required/>
                        </FormGroup>
                        <FormGroup>
                            <Label>¿Cuál fue su empleo anterior?</Label>
                            <FastInput type="textarea" 
                                value={entrevista.empleoAnterior}
                                onChange={handleChange('empleoAnterior')}
                                required/>
                        </FormGroup>
                        <FormGroup>
                            <Label>¿Porqué terminó su empleo anterior?</Label>
                            <FastInput type="textarea" 
                                value={entrevista.razonTerminoEmpleoAnterior}
                                onChange={handleChange('razonTerminoEmpleoAnterior')}
                                required/>
                        </FormGroup>
                        <FormGroup>
                            <Label>¿Qué antigüedad generó en sus últimos dos empleos?</Label>
                            <FastInput type="textarea" 
                                value={entrevista.antiguedadAnterior}
                                onChange={handleChange('antiguedadAnterior')}
                                required/>
                        </FormGroup>
                        <FormGroup>
                            <Label>¿Cuál fue su sueldo en el último empleo anterior?</Label>
                            <FastInput type="textarea" 
                                value={entrevista.sueldoAnterior}
                                onChange={handleChange('sueldoAnterior')}
                                required/>
                        </FormGroup>
                    </React.Fragment> : null
                }
                <FlexRow justifyContent='flex-end' className="FormButtons">
                    <Button type="submit" color="primary" disabled={working}>Guardar</Button>
                    <Button color="secondary" onClick={onCancel} disabled={working}>Cancelar</Button>
                </FlexRow>
            </FlexColumn>
        </Form>
    )
}