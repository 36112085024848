import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input} from 'reactstrap';

export default function StepButton({stepForm :StepForm, onComplete, stepProps, title, ...props}) {

    const [open, setOpen] = useState(false);    

    function handleClick() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false)
    }

    function handleComplete(result) {
        setOpen(false);
        onComplete(result)
    }

    return (
        <React.Fragment>
            <Button {...props} onClick={handleClick}></Button>
            <Modal isOpen={open}>
                <ModalHeader toggle={handleClose}>
                    {title}
                </ModalHeader>
                <ModalBody>
                    <StepForm {...stepProps}
                        onComplete={handleComplete}
                        onCancel={handleClose}
                        isOpen={open}
                    ></StepForm>
                </ModalBody>      
            </Modal>            
        </React.Fragment>
    )
}