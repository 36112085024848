import React, {useState, useEffect} from 'react';
import FlexRow from '../shared/FlexRow';
import {FormGroup, Input, Table, Button, Label, Form} from 'reactstrap';
import DownloadFileButton from '../shared/DownloadFileButton';
import FileInput from '../shared/FileInput'
import SectionHeader from '../shared/SectionHeader'
import usePostHttpRequest from '../hooks/usePostHttpRequest';
import useFetch from '../hooks/useFetch';

export default function CierreDefinitivo({prospectoId, vacanteId}) {

    const [fetching, response, doFetch] = useFetch(null)
    const [data, setData] = useState({
        oficio: null
    }) 
    const [dirty, setDirty] = useState(false);
    const [file, setFile] = useState(null);
    const [saving, doPost] = usePostHttpRequest();
    const [error, setError] = useState(null);

    useEffect(() => {
        if(vacanteId && prospectoId) {
            doFetch(`/cierre/${vacanteId}/${prospectoId}`);
        }        
    }, [vacanteId, prospectoId])

    useEffect(() => {
        if(response) {
            setData(response);
        }        
    }, [response])


    function handleFileChange(file) {
        setDirty(true);
        setFile(file);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        if(!file) {
            setError('Por favor adjunta el oficio correspondiente');
            return;
        }
        setError(null);
        const formData = new FormData();
        formData.append('oficio', file);
        formData.append('cierre', JSON.stringify({}));
        await doPost(`/cierre/${vacanteId}/${prospectoId}`, formData, true)
        setDirty(false);
        setFile(null);
        doFetch(`/cierre/${vacanteId}/${prospectoId}`);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <SectionHeader textAlign="left">
                CIERRE DEFINITIVO
            </SectionHeader>
            <FlexRow justifyContent="start" alignItems="center">                            
                <FileInput maxSize={10} 
                    style={{marginTop:'5px'}}
                    fileId="oficioCierre"
                    fileUrl={data.oficio}
                    working={fetching || saving}
                    onChange={handleFileChange}></FileInput>
                {
                    dirty ? 
                    <Button className="btn-blue" disabled={saving || fetching}>GUARDAR</Button> : null
                }
            </FlexRow>
            {
                error ? <p className="FontRed" style={{padding:'10px'}}>{error}</p> : null
            }
        </Form>
    )

}