import React from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label} from 'reactstrap';
import FastInput from '../shared/FastInput';
import FlexColumn from '../shared/FlexColumn';
import FlexRow from '../shared/FlexRow';
import Checkbox from '../shared/Checkbox';

export default function DatosGeneralesFormOtrosEstudios({value, onChange}) {

    function handleChange(name) {
        return function(event) {
            const target = event.target;;
            onChange({...value, [name]:target.type == 'checkbox' ? target.checked : target.value})
        }
    }

    return (
        <React.Fragment>
            <h6>Otros estudios</h6>
            <FormGroup>
                <Label></Label>
                <FastInput type="text" 
                    value={value.tipoEstudio}
                    onChange={handleChange('tipoEstudio')}/>
            </FormGroup>
            <FormGroup>
                <Label>Institución</Label>
                <FastInput type="text" 
                    value={value.institucion}
                    onChange={handleChange('institucion')}/>
            </FormGroup>
            <FormGroup>
                <Label>Fecha de inicio</Label>
                <FastInput type="date" 
                    value={value.fechaInicio}
                    onChange={handleChange('fechaInicio')}/>
            </FormGroup>
            <FormGroup>
                <Label>Fecha de término</Label>
                <FastInput type="date" 
                    value={value.fechaTermino}
                    onChange={handleChange('fechaTermino')}/>
            </FormGroup>
            <Checkbox 
            value={value.actual}
            onChange={handleChange('actual')}>Actual</Checkbox>
        </React.Fragment>
    )

}