import React, {useState, useEffect} from 'react';
import logo from './images/logo2.png'
import SideMenuItem from './shared/SideMenuItem'
import { faPlus, faUsersCog } from '@fortawesome/free-solid-svg-icons'
import {Route, withRouter} from 'react-router-dom';
import RequisicionForm from './requisiciones/RequisicionForm';
import VacanteEdit from './vacantes/VacanteEdit';
import Vacantes from './vacantes/Vacantes';
import Prospectos from './prospectos/Prospectos'
import ProcesoProspecto from './prospectos/ProcesoProspecto';
import useSession from './hooks/useSession';
import {Button} from 'reactstrap';
import FilterRequisiciones from './shared/FilterRequisiciones'
import FilterVacantes from './shared/FilterVacantes'

function Main({history, location}) {

    const [userData, login, logout] = useSession();
    const [selected, setSelected] = useState(location.pathname !== '/' ? location.pathname : '/requisiciones');

    function handleMenuChange(value) {
        setSelected(value);
    }

    useEffect(() => {
        history.push(selected)
    }, [selected, history])


    return (
        <div>
            <div className="TopBar">
                <div className="col">
                    <img src={logo} style={{width:'150px'}} alt="logo"/>
                </div>
                <div className="col justify-content-end">
                    <div className="row justify-content-end">
                        <div>{userData.nombre}</div>                                                                        
                    </div>                    
                    <div className="row justify-content-end">
                        <div>
                            <Button color="link"
                                onClick={logout}>Cerrar sesión</Button>
                        </div>                                                                        
                    </div>                    
                </div>
            </div>            
            <div className="MainArea">
                <div className="SideMenu">
                    <Route path="/" render={() => (
                        <React.Fragment>
                            <SideMenuItem title="Admin de Vacantes" icon={faUsersCog} 
                                value="/vacantes" 
                                selected={selected === '/vacantes'}
                                onSelect={handleMenuChange}>
                                <FilterVacantes></FilterVacantes>
                            </SideMenuItem>
                        </React.Fragment>
                    )}></Route>                    
                </div>
                <div className="MainAreaContent">                              
                    <Route exact path="/requisiciones/nueva" component={RequisicionForm}/>                                    
                    <Route exact path="/vacantes" component={Vacantes}/>  
                    <Route exact path="/vacantes/:_id/edit" component={VacanteEdit}/>  
                    <Route exact path="/vacantes/:_id/prospectos/:prospectoId" component={ProcesoProspecto}/>                                    
                    <Route exact path="/vacantes/:_id/prospectos" component={Prospectos}/>                                                        
                </div>   
            </div>
        </div>
    )

}

export default withRouter(Main);