import React, { useState } from 'react';
import FlexRow from '../shared/FlexRow'
import FlexColumn from '../shared/FlexColumn';
import { faEnvelope, faPencilAlt, faArchive } from '@fortawesome/free-solid-svg-icons'
import { calcFechaLimite } from '../shared/Utils'
import styles from './ListVacanteItem.module.css';
import SaveAsButton from '../shared/SaveAsButton';
import BorderButton from '../shared/BorderButton';
import ReqHistory from './ReqHistory';
import { FormGroup, Input } from 'reactstrap'
import HttpRequest from '../shared/HttpRequest';
import moment from 'moment';


export default function ListVacanteItem({ vacante, onInviteClick, onArchivarClick, onEditClick, onClick, onStatusChange }) {

    const [working, setWorking] = useState(false)
    const datosGenerales = vacante.requisicion[0].datosGenerales;
    const requisicion = vacante.requisicion[0];

    const newStatus = [
        { value: 'En proceso', label: 'En proceso' },
        { value: 'Pausada', label: 'Pausada' },
        { value: 'Cancelación por Cliente', label: 'Cancelación por Cliente' },
    ];

    const fechaLimite = calcFechaLimite(datosGenerales.fecha, vacante.tiempoSeleccion, requisicion.tiempoPause);

    if (!requisicion.history) {
        requisicion.history = [];
    }

    function handleInviteClick(event) {
        event.stopPropagation();
        onInviteClick(vacante._id, datosGenerales.empresa, datosGenerales.nombrePuesto)
    }

    function handleArchivarClick(event) {
        event.stopPropagation();
        onArchivarClick(requisicion._id)
    }

    function handleEditClick(event) {
        event.stopPropagation();
        onEditClick(requisicion._id);
    }

    function handleClick(event) {
        if (event.target.type === 'select-one') {
            return;
        }
        onClick(vacante._id)
    }

    async function handleStatusChange(event) {
        setWorking(true)
        const newValue = event.target.value;
        let tiempoPause = 0;
        if (event.target.value === 'En proceso') {
            // resumimos la requisicion
            const response = await HttpRequest.post(`/requisiciones/${requisicion._id}/resume`)
            tiempoPause = response.data.tiempoPause;
        } else if (event.target.value === 'Pausada') {
            // pausamos la requisicion
            await HttpRequest.post(`/requisiciones/${requisicion._id}/pause`)
        }
        else {
            // cancelamos la requisicion
            await HttpRequest.post(`/requisiciones/${requisicion._id}/cancel`)
        }
        onStatusChange(vacante._id, newValue, tiempoPause)
        setWorking(false);
    }

    const borderColor = () => {
        const today = moment(new Date().toJSON().slice(0, 10).replace(/-/g, '/'));
        const fecha = moment(fechaLimite)
        let color = '#7BB742' //green
        if (!fechaLimite || requisicion.status === 'Completada') return color
        if (today.diff(fecha, 'days') > 5)  color = '#FFCC00' //yellow
        if (today.diff(fecha, 'days') >= 15)  color = '#CC0000' //red
        return color
    }

    return (
        <FlexColumn className={styles.container} style={{ borderLeftColor: borderColor() }} onClick={handleClick}>
            <FlexRow className={styles.containerRow}>
                <FlexColumn className={styles.leftColumn}>
                    <FlexRow>
                        <div className="Bold">{datosGenerales.empresa}</div>
                        <div className="Bold">{datosGenerales.sucursal}</div>
                        <div className="Bold">{datosGenerales.nombrePuesto}</div>
                    </FlexRow>
                </FlexColumn>
                <FlexColumn className={styles.rightColumn}>
                    <div className="Bold">{datosGenerales.fecha}</div>
                </FlexColumn>
            </FlexRow>
            <FlexRow className={styles.containerRow}>
                <FlexColumn className={styles.leftColumn}>
                    <FlexRow>
                        <div className="Bold" style={{ flexGrow: 0, marginRight: '10px' }}>Estatus:</div>
                        {
                            requisicion.status && requisicion.status !== null ?
                                <FormGroup className="NoFlex">
                                    <Input type="select"
                                        className={styles.statusSelect}
                                        value={requisicion.status}
                                        disabled={working}
                                        onChange={handleStatusChange}>
                                        {requisicion.status
                                            ? newStatus.map(({ value, label }, index) => {
                                                return value ? (
                                                    <option
                                                        key={value + index}
                                                        value={value}
                                                        selected={requisicion.status === value ? true : false}
                                                    >
                                                        {label}
                                                    </option>
                                                ) : null;
                                            })
                                            : newStatus.map(({ value, label }, index) => {
                                                return value ? (
                                                    <option key={value + index} value={value}>
                                                        {label}
                                                    </option>
                                                ) : null;
                                            })}
                                    </Input>
                                </FormGroup> :
                                <div>{requisicion.status}</div>
                        }
                        {
                            requisicion.status !== 'Pausada' && requisicion.status !== 'Cancelación por Cliente' ?
                                <div>
                                    <span className="Bold">Fecha límite: </span>
                                    <span>{fechaLimite}</span>
                                </div> : <div></div>
                        }
                    </FlexRow>
                </FlexColumn>
                <FlexColumn className={styles.rightColumn}>
                    <BorderButton icon={faPencilAlt}
                        onClick={handleEditClick}>EDITAR REQUISICIÓN</BorderButton>
                </FlexColumn>
            </FlexRow>
            <FlexRow className={styles.containerRow}>
                <FlexColumn>
                    <FlexRow>
                        <span>Descargar requisición</span>
                        <SaveAsButton
                            style={{ marginLeft: '10px' }}
                            iconButton={true}
                            link={`/requisiciones/${requisicion._id}/${requisicion.history.length - 1}`}
                            title="requisicion.pdf">DESCARGAR PDF</SaveAsButton>
                    </FlexRow>
                </FlexColumn>
                <FlexColumn className={styles.rightColumn}>
                    <BorderButton icon={faEnvelope} green
                        onClick={handleInviteClick}>INVITAR PARTICIPANTE</BorderButton>
                </FlexColumn>
            </FlexRow>
            <FlexRow className={styles.containerRow}>
                <FlexColumn>

                </FlexColumn>
                <FlexColumn className={styles.rightColumn}>
                    <BorderButton icon={faArchive} red
                        onClick={handleArchivarClick}>ARCHIVAR</BorderButton>
                </FlexColumn>
            </FlexRow>
            <ReqHistory requisicion={requisicion}></ReqHistory>
        </FlexColumn>
    )

}