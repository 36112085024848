import React, {useState, useEffect} from 'react';
import HttpRequest from '../shared/HttpRequest';
import SectionHeader from '../shared/SectionHeader';
import FlexRow from '../shared/FlexRow';
import { Button, Form } from 'reactstrap';
import RequisicionDatosGenerales from './RequisicionDatosGenerales'
import RequisicionPerfil from './RequisicionPerfil'
import RequisicionCaracteristicas from './RequisicionCaracteristicas'
import RequisicionHerramientas from './RequisicionHerramientas'
import RequisicionContactos from './RequisicionContactos'
import RequisicionOfertaLaboral from './RequisicionOfertaLaboral'
import RequisicionPrestaciones from './RequisicionPrestaciones'
import RequisicionAdicionales from './RequisicionAdicionales'
import RequisicionAutorizadoPor from './RequisicionAutorizadoPor'
import ConfirmationDialog from '../shared/ConfirmationDialog';
import RequisicionAreasInteresadas from './RequisicionAreasInteresadas';

const herramientasOptions = [
    'Escritorio', 'Computadora', 'Celular', 'Teléfono Fijo', 'Vehículo', 'Motocicleta', 'Tablet',
    'Correo Electrónico', 'Tarjetas de Presentación', 'Vales de gasolina y/o códigos', 'Uniforme de trabajo',
    'Equipo de seguridad', 'Otro'
]



const areaInteresadaDefault = {
    nombre: '',
    departamento: '',
    correo: ''
};

const areasInteresadasDefault = [{...areaInteresadaDefault}, {...areaInteresadaDefault}, {...areaInteresadaDefault}]

function RequisicionForm({history, match}) {

    const editMode = !!(match.params && match.params._id);

    const [datosGenerales, setDatosGenerales] = useState({
        fecha: new Date(),
        numeroVacantes: 1,
        motivo: 'Nueva creación',
        causaReemplazo: 'N/A',
        nombrePuesto: '',
        objetivo: '',
        contacto: '',
        contactoPuesto: '',
        contactoTelefono: '',
        contactoCorreo: '',
        procesoContacto: 'Por medio de correos',
        direccion: '',
        empresa:'',
        sucursal:''
    })

    const [perfil, setPerfil] = useState({
        edad:'Indistinto',
        sexo:'Indistinto',
        estadoCivil:'Indistinto',
        nivelAcademico:'Primaria',
        nivelAcademicoEstatus:'Titulado',
        experienciaPrevia:'Si',
        experienciaPreviaTiempo:'',
        software:[],
        idioma:[],
        experienciaPreviaDetalle:'',
        disponibilidad:'Ninguna',
        disponibilidadOtro: '',
        documentosAdicionales: 'N/A',
        documentosAdicionalesOtro:'',
        tipoLicencia: 'Ninguna'
    })

    const [caracteristicas, setCaracteristicas] = useState([])

    const [herramientas, setHerramientas] = useState(herramientasOptions.map(h => ({
        checked: false,
        nombre: h,
        privado: false,
        descripcion:''
    })))

    const [contactos, setContactos] = useState([]);

    const [ofertaLaboral, setOfertaLaboral] = useState({
        sueldo:0,
        tipoSueldo:'Nominales',
        periodoPago:'Quincenal'
    })

    const [prestaciones, setPrestaciones] = useState({
        ley:[],
        superiores:[]
    })

    const [adicionales, setAdicionales] = useState({
        periodoEvaluacion:'',
        departamento:'',
        horario:'',
        personalACargo:'',
        personalACargoNumero:0,
        contactoInterno1:{
            nombre:'',
            telefono: ''
        },
        contactoInterno2:{
            nombre:'',
            telefono: ''
        },
        contactoInterno3:{
            nombre:'',
            telefono: ''
        },
        contactoExterno1:{
            nombre:'',
            telefono: ''
        },
        contactoExterno2:{
            nombre:'',
            telefono: ''
        },
        contactoExterno3:{
            nombre:'',
            telefono: ''
        }
    })

    const [autorizadoPor, setAutorizadoPor]= useState({
        nombre:'',
        departamento:'',
        responsable:''
    })

    const [working, setWorking] = useState(false)
    const [dirty, setDirty] = useState(false);
    const [archived, setArchived] = useState(false);
    const [areasInteresadas, setAreasInteresadas] = useState(areasInteresadasDefault)

    useEffect(() => {

        async function fetchRequisicion() {
            setWorking(true)
            const response = await HttpRequest.get(`/requisiciones/${match.params._id}/data`);
            if(response.data.perfil) {
                setPerfil(response.data.perfil)
            }
            setAdicionales(response.data.adicionales);
            setAutorizadoPor(response.data.autorizadoPor);
            setCaracteristicas(response.data.caracteristicas);
            setContactos(response.data.contactos);
            setDatosGenerales(response.data.datosGenerales)
            setHerramientas(response.data.herramientas);
            setOfertaLaboral(response.data.ofertaLaboral);
            setPrestaciones(response.data.prestaciones)
            setArchived(response.data.archived)
            setAreasInteresadas(response.data.areasInteresadas)
            setWorking(false)
        }

        if(editMode) {
            fetchRequisicion();
        }
    }, [editMode, match.params._id])

    function handleChange(handler) {
        return function(data) {
            setDirty(true);
            handler(data);
        }
    }

    async function handleSubmit() {
        const body = {          
            _id: match.params._id,  
            data: {
                datosGenerales, 
                perfil,
                caracteristicas,
                herramientas,
                contactos,
                ofertaLaboral,
                prestaciones,
                adicionales,
                autorizadoPor
            }            
        }
        setWorking(true);
        try {
            await HttpRequest.post('/requisiciones', body)
            setWorking(false);
            if(!editMode) {
                history.push('/vacantes');
            }            
        } catch(e) {
            console.log(e);
            setWorking(false);
        }
    }

    return (
        <div>
            {
                editMode ?
                <h4>Editar vacante</h4>
                :
                <React.Fragment>
                    <h4>SOLICITAR VACANTE</h4>
                    <p>
                        Llena los campos con la información de la vacante que solicitas, a la brevedad 
                        trabajaremos en tu solicitud para brindarte los candidatos más aptos.
                    </p>
                </React.Fragment>
            }            
            <SectionHeader>
                DESCRIPCIÓN Y PERFIL DEL PUESTO
            </SectionHeader>
            <p>
                Este formato de requerimiento responde a la necesidad de obtener mayor cantidad de 
                información posible sobre la vacante y el perfil del personal requerido para ocupar 
                el puesto, con el fin de realizar una selección acorde a sus necesidades.
            </p>            
            <ConfirmationDialog skip={!editMode}
                title="Editar requisición"
                body="Al editar la requisición se creará una nueva versión y la versión actual quedará guardada en el historial, este cambio no se puede deshacer. ¿Deseas continuar?">
                {
                    onConfirm => (
                        <Form onSubmit={onConfirm(handleSubmit)}>
                            <RequisicionDatosGenerales value={datosGenerales} onChange={handleChange(setDatosGenerales)} showDirty={editMode}/>
                            <br/>
                            <RequisicionPerfil value={perfil} onChange={handleChange(setPerfil)} showDirty={editMode}/>
                            <br/>
                            <RequisicionCaracteristicas value={caracteristicas} onChange={handleChange(setCaracteristicas)} showDirty={editMode}/>
                            <br/>
                            <RequisicionHerramientas value={herramientas} onChange={handleChange(setHerramientas)} showDirty={editMode}/>
                            <br/>
                            <RequisicionContactos value={contactos} onChange={handleChange(setContactos)} showDirty={editMode}/>
                            <br/>
                            <RequisicionOfertaLaboral value={ofertaLaboral} onChange={handleChange(setOfertaLaboral)} showDirty={editMode}/>
                            <br/>
                            <RequisicionPrestaciones value={prestaciones} onChange={handleChange(setPrestaciones)} showDirty={editMode}/>
                            <br/>
                            <RequisicionAdicionales value={adicionales} onChange={handleChange(setAdicionales)} showDirty={editMode}/>            
                            <br/>
                            {
                                areasInteresadas ?
                                <RequisicionAreasInteresadas value={areasInteresadas} onChange={handleChange(setAreasInteresadas)}></RequisicionAreasInteresadas>
                                : ''
                            }
                            <br/>
                            <FlexRow justifyContent="center">
                                <Button className="btn-blue" type="submit" disabled={working || (!dirty && editMode)}>GUARDAR</Button>
                            </FlexRow>                
                        </Form>
                    )
                }
            </ConfirmationDialog>
        </div>
    )
}

export default RequisicionForm
