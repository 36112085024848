import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import FlexRow from './FlexRow';
import HttpRequest from './HttpRequest';

export default function StartVideoCallModal({eventId, isOpen, onClose}) {

    const [working, setWorking] = useState(false)
    const [error, setError] = useState(null)

    async function handleClickOk() {
        setWorking(true);
        setError(null)
        try {
            const response = await HttpRequest.get('/eventos/video/' + eventId);
            window.open(response.data.url, '_blank');
            setWorking(false)
            onClose()
        } catch(e) {
            setError('Hubo un error iniciando la videollamada')
        }
        
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader toggle={onClose}>
                Iniciar videollamada
            </ModalHeader>
            <ModalBody>
                <p>
                    {
                        error ? error : '¿Iniciar la videollamada para este evento?'
                    }
                </p>
                {
                    error ?
                    <FlexRow justifyContent='flex-end' className="FormButtons">
                        <Button color="primary" onClick={onClose} >Cerrar</Button>   
                    </FlexRow> :
                    <FlexRow justifyContent='flex-end' className="FormButtons">
                        <Button color="primary" onClick={handleClickOk} disabled={working}>Iniciar</Button>   
                        <Button color="secondary" onClick={onClose} disabled={working}>Cancelar</Button>
                    </FlexRow>
                }
            </ModalBody>
        </Modal>
    )

}