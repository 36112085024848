import React, {useState, useEffect} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input} from 'reactstrap';
import FastInput from '../shared/FastInput';
import FlexColumn from '../shared/FlexColumn';
import FlexRow from '../shared/FlexRow';
import HttpRequest from '../shared/HttpRequest';
import {formatDate} from '../shared/Utils'

export default function EntrevistaPresencialFormFields({vacanteId, prospectoId, puesto, onComplete, onCancel, isOpen}) {
    
    // preguntas base 
    const [entrevista, setEntrevista] = useState({
        fecha: formatDate(new Date()),
        personal: '',
        experienciaLaboral: '',
        duracionesRelacionesLaborales: '',
        razonTermino: '',
        jefeAnterior:'',
        tiempoVacio: '',
        personasACargo:'',
        problema: '',
        presion: '',
        razonInteresado: '',
        razonMerece: '',
        actividadesExtra:'',
        debilidad: '',
        trabajoIdeal: '',
        individualEquipo: '',
        salarioAnterior: '',
        gastoMensual: '',
        esperaGanar: ''
    })
    // campo para definir pregunta extra
    const [newExtra, setNewExtra] = useState('')
    // ppreguntas extra
    const [extra, setExtra] = useState([]);
    const [working, setWorking] = useState(false)


    useEffect(() => {
        async function fetchData() {
            const response = await HttpRequest.get(`/prospectos/prospecto/${vacanteId}/${prospectoId}/proceso/entrevistaPersonal`);
            const {result, data} = response.data;
            if(result === 'ok') {
                const e = data.datos;
                e.entrevista.fecha = formatDate(data.datos.fecha);
                setEntrevista(e.entrevista);
                setExtra(e.extra)
            }
        }
        if(isOpen) {
            console.log('fetching personal: ' +  prospectoId)
            fetchData();
        }     
    }, [prospectoId, isOpen, vacanteId])

    function handleChange(name) {
        return function(event) {
            const target = event.target;
            setEntrevista({...entrevista, [name]: target.type === 'checkbox' ? target.checked : target.value})
        }
    }

    function handleNewExtraChange(event) {
        setNewExtra(event.target.value);
    }

    function handleClickAdd() {
        setExtra([...extra, {
            question: newExtra,
            value: ''
        }])
        setNewExtra('')
    }

    function handleChangeExtra(index) {
        return function(event) {
            const e = [...extra];
            e[index].value = event.target.value;
            setExtra(e);
        }
    }

    function handleClickRemoveExtra(index) {
        return function() {
            setExtra(extra.filter((e, i) => i !== index));
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        setWorking(true);
        const formData = new FormData();
        formData.append('stepData', JSON.stringify({
            stepName:'entrevistaPersonal',
            stepData: {entrevista, extra}
        }));
        const response = await HttpRequest.postMultipart(`/prospectos/prospecto/${vacanteId}/${prospectoId}/proceso`, formData)        
        setWorking(false);
        onComplete(response.data);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FlexColumn>
                <FormGroup>
                    <Label>Fecha</Label>
                    <FastInput type="date" 
                        value={entrevista.fecha}
                        onChange={handleChange('fecha')}/>
                </FormGroup>        
                <FormGroup>
                    <Label>Háblame de ti en lo personal</Label>
                    <FastInput type="textarea" 
                        value={entrevista.personal}
                        onChange={handleChange('personal')}/>
                </FormGroup>
                <FormGroup>
                    <Label>Háblame de tu experiencia laboral</Label>
                    <FastInput type="textarea" 
                        value={entrevista.experienciaLaboral}
                        onChange={handleChange('experienciaLaboral')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿Cuánto han durado tus anteriores relaciones laborales?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.duracionesRelacionesLaborales}
                        onChange={handleChange('duracionesRelacionesLaborales')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿Por qué se terminó la relación laboral?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.razonTermino}
                        onChange={handleChange('razonTermino')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿Qué me puedes decir de tu jefe anterior?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.jefeAnterior}
                        onChange={handleChange('jefeAnterior')}/>
                </FormGroup>
                <FormGroup>
                    <Label>Si existe un hueco en el tiempo de experiencia laboral. ¿Por qué y qué hacías en ese tiempo?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.tiempoVacio}
                        onChange={handleChange('tiempoVacio')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿Cuántas personas estuvieron a tu cargo?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.personasACargo}
                        onChange={handleChange('personasACargo')}/>
                </FormGroup>
                <FormGroup>
                    <Label>Menciona un problema que se haya suscitado en tu vida laboral y cómo lo solucionaste</Label>
                    <FastInput type="textarea" 
                        value={entrevista.problema}
                        onChange={handleChange('problema')}/>
                </FormGroup>
                <FormGroup>
                    <Label>Menciona una situación donde te desempeñaste bajo presión y cómo lo solucionaste</Label>
                    <FastInput type="textarea" 
                        value={entrevista.presion}
                        onChange={handleChange('presion')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿Por qué te interesa la vacante?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.razonInteresado}
                        onChange={handleChange('razonInteresado')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿Por qué crees que es para ti el puesto?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.razonMerece}
                        onChange={handleChange('razonMerece')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿Qué otras actividades aparte de trabajar realizas?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.actividadesExtra}
                        onChange={handleChange('actividadesExtra')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿Cuál es tu mayor debilidad o defecto?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.debilidad}
                        onChange={handleChange('debilidad')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿Cómo describirías tu trabajo ideal?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.trabajoIdeal}
                        onChange={handleChange('trabajoIdeal')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿Trabajas mejor de forma individual o en equipo?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.individualEquipo}
                        onChange={handleChange('individualEquipo')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿Cuál era su salario anterior?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.salarioAnterior}
                        onChange={handleChange('salarioAnterior')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿A cuánto asciende su gasto mensual?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.gastoMensual}
                        onChange={handleChange('gastoMensual')}/>
                </FormGroup>
                <FormGroup>
                    <Label>¿Cuánto espera ganar?</Label>
                    <FastInput type="textarea" 
                        value={entrevista.esperaGanar}
                        onChange={handleChange('esperaGanar')}/>
                </FormGroup>
                {
                    extra.map((e, i) => (
                        <FormGroup key={i}>
                            <FlexRow>
                                <Label>{e.question}</Label>
                                <Button color="link" onClick={handleClickRemoveExtra(i)} disabled={working}>Eliminar</Button>
                            </FlexRow>
                            <FastInput type="textarea" 
                                value={e.value}
                                onChange={handleChangeExtra(i)}/>
                        </FormGroup>
                    ))
                }
                <FlexRow>
                    <FormGroup>
                        <Label>Agregar otra pregunta</Label>
                        <Input type="text" 
                            value={newExtra}
                            onKeyDown={event => {
                                if(event.key === 'Enter') {            
                                    event.preventDefault();
                                    event.stopPropagation();
                                    if(!working && newExtra) {
                                        handleClickAdd();
                                    }
                                }  
                            }}
                            onChange={handleNewExtraChange}/>
                    </FormGroup>
                    <Button color="primary" onClick={handleClickAdd} 
                        disabled={working || !newExtra}
                        style={{marginTop:'32px'}}>Agregar</Button>
                </FlexRow>                
                <FlexRow justifyContent='flex-end' className="FormButtons">
                    <Button type="submit" color="primary" disabled={working}>Guardar</Button>
                    <Button color="secondary" onClick={onCancel} disabled={working}>Cancelar</Button>
                </FlexRow>
            </FlexColumn>
        </Form>
    )

}