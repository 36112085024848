import React, {useState} from 'react';
import FlexRow from '../shared/FlexRow';
import {  FormGroup, Label, Table } from 'reactstrap';
import FastInput from '../shared/FastInput';
import Checkbox from '../shared/Checkbox'
import FlexColumn from '../shared/FlexColumn';
import ContactoTelefono from './ContactoTelefono';
import styles from './RequisicionForm.module.css'


export default function RequisicionAdicionales({value, onChange, showDirty}) {

    const [dirty, setDirty] = useState(false);

    function handleChange(name) {
        return function(event) {
            setDirty(true)
            onChange({...value, [name]:event.target.value})
        }
    }   

    function handleChangeContacto(name) {
        return function(contacto) {
            setDirty(true)
            onChange({...value, [name]: contacto})
        }
    }

    return (
        <div className={`${dirty && showDirty ? styles.sectionDirty : ''}`}>
           <FlexRow>
               <FormGroup>
                   <Label>Periodo de evaluación a considerar para </Label>
                   <FastInput placeholder="" type="text" 
                    value={value.periodoEvaluacion}
                    onChange={handleChange('periodoEvaluacion')}
                    required></FastInput>
               </FormGroup>
               <FormGroup>
                   <Label>Departamento al que pertenece</Label>
                   <FastInput placeholder="" type="text" 
                    value={value.departamento}
                    onChange={handleChange('departamento')}
                   required></FastInput>
               </FormGroup>
           </FlexRow>
           <FormGroup>
               <Label>Horario de trabajo</Label>
               <FastInput placeholder="" type="textarea" 
                value={value.horario}
                onChange={handleChange('horario')}
               required></FastInput>
           </FormGroup>
           <FlexRow>
               <Checkbox 
                value={value.personalACargo}
                onChange={handleChange('personalACargo')}>Tendrá personal a su cargo</Checkbox>
               <div style={{margin:'10px'}}></div>
               <FormGroup>
                   <Label>Cuantas personas</Label>
                   <FastInput placeholder="" type="number" 
                    value={value.personalACargoNumero}
                    onChange={handleChange('personalACargoNumero')}
                   required></FastInput>
               </FormGroup>
           </FlexRow>
           <br/>
           <FlexRow>
               <FlexColumn>
                   <Table>
                       <thead>
                           <tr>
                               <th>Contactos internos</th>
                           </tr>
                       </thead>
                       <tbody>
                           <tr>
                               <td>
                                   <ContactoTelefono value={value.contactoInterno1}
                                    onChange={handleChangeContacto('contactoInterno1')}></ContactoTelefono>
                               </td>
                           </tr>
                           <tr>
                               <td>
                                   <ContactoTelefono value={value.contactoInterno2}
                                   onChange={handleChangeContacto('contactoInterno2')}></ContactoTelefono>
                               </td>
                           </tr>
                           <tr>
                               <td>
                                <ContactoTelefono value={value.contactoInterno3}
                                   onChange={handleChangeContacto('contactoInterno3')}></ContactoTelefono>
                               </td>
                           </tr>
                       </tbody>
                   </Table>
               </FlexColumn>
               <FlexColumn>
                   <Table>
                       <thead>
                           <tr>
                               <th>Contactos externos</th>
                           </tr>
                       </thead>
                       <tbody>
                           <tr>
                               <td>
                                <ContactoTelefono value={value.contactoExterno1}
                                   onChange={handleChangeContacto('contactoExterno1')}></ContactoTelefono>
                               </td>
                           </tr>
                           <tr>
                               <td>
                                <ContactoTelefono value={value.contactoExterno2}
                                   onChange={handleChangeContacto('contactoExterno2')}></ContactoTelefono>
                               </td>
                           </tr>
                           <tr>
                               <td>
                               <ContactoTelefono value={value.contactoExterno3}
                                   onChange={handleChangeContacto('contactoExterno3')}></ContactoTelefono>
                               </td>
                           </tr>
                       </tbody>
                   </Table>
               </FlexColumn>                    
           </FlexRow>
        </div>
    )

}