import React from 'react';
import Date from '../shared/Date';
import {Table} from 'reactstrap'
import moment from 'moment'

export default function ProspectosTable({prospectos, onClick}) {

    function handleClick(prospectoId) {
        return function(event) {
            onClick(prospectoId);
        }
    }

    return (
        <Table className="ProspectosTable">
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                    <th>Fecha</th>
                    <th>Edad</th>
                    <th>Educacion</th>
                </tr>
            </thead>
            <tbody>
                {
                    prospectos.map(p => (
                        <tr key={p._id}
                            onClick={handleClick(p._id)}>
                            <td></td>
                            <td>
                                {p.nombre}<br/>
                                {p.email}
                            </td>
                            <td>
                                <Date>{p.createdAt}</Date>
                            </td>
                            <td>{moment().diff(moment(p.fechaNacimiento),'years')}</td>
                            <td>{p.educacion}</td>
                            <td>VER</td>
                        </tr>
                    ))
                }
                {/* <tr>
                    <td></td>
                    <td>Artuo Valenzuela</td>
                    <td>Hoy</td>
                    <td>24</td>
                    <td>Educación media superior</td>
                    <td>VER</td>
                </tr> */}
            </tbody>
        </Table>
    )
}