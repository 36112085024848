import React, { useState, useEffect } from 'react';
import FlexRow from '../shared/FlexRow';
import { FormGroup, Input, Table, Button, Label, Form } from 'reactstrap';
import DownloadFileButton from '../shared/DownloadFileButton';
import FileInput from '../shared/FileInput'
import usePostHttpRequest from '../hooks/usePostHttpRequest';
import useFetch from '../hooks/useFetch';

export default function PostAsignacionGarantia({ prospectoId, vacanteId }) {

    const [fetching, response, doFetch] = useFetch(null)
    const [data, setData] = useState({
        dias: 20,
        otro: null,
        oficio: null
    })
    const [dirty, setDirty] = useState(false);
    const [file, setFile] = useState(null);
    const [saving, doPost] = usePostHttpRequest();
    const [error, setError] = useState(null);

    useEffect(() => {
        if (vacanteId && prospectoId) {
            doFetch(`/postasignaciones/garantia/${vacanteId}/${prospectoId}`);
        }
    }, [vacanteId, prospectoId])

    useEffect(() => {
        if (response) {
            setData(response);
        }
    }, [response])

    function handleChange(name) {
        return function (event) {
            setDirty(true)
            setData({ ...data, [name]: event.target.value })
        }
    }

    function handleFileChange(file) {
        setDirty(true);
        setFile(file);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        if (!file) {
            setError('Por favor adjunta el oficio correspondiente');
            return;
        }
        setError(null);
        const formData = new FormData();
        formData.append('oficio', file);
        formData.append('garantia', JSON.stringify({
            dias: data.dias,
            otro: data.otro
        }));
        await doPost(`/postasignaciones/garantia/${vacanteId}/${prospectoId}`, formData, true)
        setDirty(false);
        setFile(null);
        doFetch(`/postasignaciones/garantia/${vacanteId}/${prospectoId}`);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FlexRow justifyContent="start" alignItems="center">
                <Label>Días</Label>
                <FormGroup className="NoFlex">
                    <Input type="select"
                        value={data.dias}
                        onChange={handleChange('dias')}
                        style={{ width: '100px' }}>
                        <option>20</option>
                        <option>30</option>
                        <option>60</option>
                        <option>90</option>
                        <option>Otro</option>
                    </Input>
                </FormGroup>
                <FormGroup className="NoFlex">
                    {
                        data.dias === 'Otro' ?
                            <Input type="text"
                                value={data.otro}
                                onChange={handleChange('otro')}
                                style={{ width: '100px' }}>
                            </Input>
                            : ''
                    }
                </FormGroup>
                {/* <Button className="btn-blue">ADJUNTAR OFICIO</Button> */}
                <FileInput maxSize={10}
                    style={{ marginTop: '5px' }}
                    fileId="oficioPostAsignacion"
                    fileUrl={data.oficio}
                    working={fetching || saving}
                    onChange={handleFileChange}></FileInput>
                {
                    dirty ?
                        <Button className="btn-blue" disabled={saving || fetching}>GUARDAR</Button> : null
                }
            </FlexRow>
            {
                error ? <p className="FontRed" style={{ padding: '10px' }}>{error}</p> : null
            }
        </Form>
    )

}