import React from 'react';

function Checkbox({children, style, onChange, disabled, checked, readOnly}) {

    return (
        <label className="Checkbox" style={style}>            
          <input type="checkbox" onChange={onChange} checked={checked} disabled={disabled} readOnly={readOnly}></input>
          <span className="checkmark"></span>
          {children}
        </label>
    )

}

export default Checkbox;