import React, {useState, useEffect} from 'react'
import {FormGroup, Label, Input} from 'reactstrap';

export default function withFilterOptions(name, fetchFunction) {
    return function ({value, onChange}) {

        const [options, setOptions] = useState([])

        useEffect(() => {
            async function fetchOptions() {
                const response = await fetchFunction();
                setOptions(response.data)
            }        

            fetchOptions();
        }, [])

        return (
            <FormGroup>
              <Label>{name}</Label>
              <Input type="select" 
                name="select" 
                value={value}
                onChange={onChange}
                style={{background:'#f6f6f6', color:'#A3A3A3'}}>
                <option value="any"> </option>
                {
                    options.map((o, i) => (
                        <option key={i} value={o}>{o}</option>
                    ))
                }
              </Input>          
            </FormGroup>
        )

    }
}