import React, {useState, useEffect, useCallback} from 'react';
import FlexRow from '../shared/FlexRow';
import FlexColumn from '../shared/FlexColumn';
import SelectFolderProspecto from './SelectFolderProspecto';
import SectionHeader from '../shared/SectionHeader'
import HttpRequest from '../shared/HttpRequest';
import Timeline from '../shared/Timeline';
import {FormGroup, Input, Table, Button, Label} from 'reactstrap';
import RemainingDays from './RemainingDays';
import ProspectoCalendar from './ProspectoCalendar'
import StepButton from '../shared/StepButton';
import DatosGeneralesFormFields from './DatosGeneralesFormFields';
import EntrevistaTelefonicaFormFields from './EntrevistaTelefonicaFormFields';
import BasicStepFormFields from './BasicStepFormFields';
import BasicActiveFormFields from './BasicActiveFormFields';
import ModalReferencias from './ModalReferencias';
import PsicometricoActiveFormFields from './PsicometricoActiveFormFields';
import ResultadoFinal from './ResultadoFinal';
import PostAsignacion from './PostAsignacion'
import CierreDefinitivo from './CierreDefinitivo';
import MostrarAClienteToggler from './MostrarAClienteToggler';
import ProspectoVisibleToggler from './ProspectoVisibleToggler';
import AddEntrevistaPersonalFormFields from './AddEntrevistaPersonalFormFields'
import EntrevistaPresencialFormFields from './EntrevistaPresencialFormFields'
import moment from 'moment-timezone'
import RejectedByClientPanel from './RejectedByClientPanel';

/**
 * Pagina principal que renderiza el proceso del prospecto:
 * - lineas de tiempo
 * - pasos
 * - calendario
 * - cierre
 */
export default function ProcesoProspecto({match}) {

    const [basicData, setBasicData] = useState({
        nombre: ''        
    })

    const [prospecto, setProspecto] = useState({
        proceso:{
            infoGeneral:{},
            pasos:[],
            resultado: {
                datos: {}
            }
        }
    });

    const [vacante, setVacante] = useState({
        tiempoSeleccion: 0,
        requisicion:[{
            datosGenerales:{
                nombrePuesto:'',
                fecha: new Date()
            }
        }]
    });  

    const [postAsignacionEvents, setPostAsignacionEvents] = useState([])  

    const fetchProspecto = useCallback(async function() {
        const response = await HttpRequest.get(`/prospectos/prospecto/${match.params._id}/${match.params.prospectoId}`);
        setProspecto(response.data);
    }, [match.params._id, match.params.prospectoId])

    useEffect(() => {

        async function fecthBasicData() {
            const response = await HttpRequest.get(`/prospectos/prospecto/${match.params._id}/${match.params.prospectoId}/data`);
            setBasicData(response.data);
        }

        fetchProspecto();
        fecthBasicData();
    }, [match.params.prospectoId, match.params._id, fetchProspecto])

    useEffect(() => {
        async function fetchVacante() {
            const response = await HttpRequest.get(`/vacantes/${match.params._id}`);
            setVacante(response.data);
        }
        fetchVacante();
    }, [prospecto.vacanteId, match.params._id])

    function handleActiveCompleteModal(stepName) {
        const p = JSON.parse(JSON.stringify(prospecto));    
        const pasoIndex = p.proceso.pasos.findIndex(p => p.nombre === stepName);        
        p.proceso.pasos[pasoIndex].status = 'current';
        setProspecto(p)
    }

    function handleCompleteModal(prospecto) {
        setProspecto(prospecto)
    }

    function ActiveButtonForCase({p, next}) {

        if(p.status === 'done' || (p.status === 'current' && p.nombre !== 'evaluacionPsicometrica')) {
            return null;
        }

        const props = {
            className:'btn-white',
            disabled: p.status !== 'next',
            title:p.uiNombre,
            onComplete: handleActiveCompleteModal,
            stepProps: {
                status: p.status,
                prospectoId: prospecto._id,
                vacanteId: prospecto.vacanteId,
                stepName: p.nombre
            }
        }                              
        switch(p.nombre) {
            case 'evaluacionPsicometrica': {
                props.stepForm = PsicometricoActiveFormFields;                
                if(p.status === 'current' || p.status === 'waitingConfirmation') {
                    props.disabled = false;
                    props.children = p.status === 'current' ? 'Reiniciar' : 'Esperando confirmación'
                    return (
                        <StepButton {...props}></StepButton>
                    )
                }  
                break;              
            }
            default: {
                props.stepForm = BasicActiveFormFields;
                break;
            }                 
        }     

        return <StepButton {...props}>Activar</StepButton>
    }

    function StepButtonForCase({p}) {
        const props = {
            className:'btn-white',
            disabled:p.status !== 'done' && 
                p.status !== 'current' && 
                p.status !== 'waitingConfirmation' &&
                p.nombre != 'infoGeneral',
            title:p.uiNombre,
            onComplete: handleCompleteModal,
            stepProps: {
                prospectoId: prospecto._id,
                vacanteId: prospecto.vacanteId
            }
        }                              
        switch(p.nombre) {
            case 'infoGeneral': {
                props.stepForm = DatosGeneralesFormFields;
                break;
            }
            case 'entrevistaTelefonica': {
                props.stepForm = EntrevistaTelefonicaFormFields;
                props.stepProps.puesto = vacante.requisicion[0].datosGenerales.nombrePuesto
                break
            }
            case 'referenciasLaborales': {
                props.stepForm = ModalReferencias;
                break;
            }
            case 'entrevistaPersonal': {
                props.stepForm = EntrevistaPresencialFormFields;
                props.stepProps.puesto = vacante.requisicion[0].datosGenerales.nombrePuesto
                break;
            }
            default: {
                props.stepForm = BasicStepFormFields;
                props.stepProps.stepName = p.nombre
                break;
            }                 
        }        
        return <StepButton {...props}>Ver</StepButton>
    }

    function handleEventsLoaded(data) {
        const postAsignacionEvents = data.events.filter(e => e.eventType === 'postAsignacion')
        setPostAsignacionEvents(postAsignacionEvents)
    }


    return (
        <FlexColumn justifyContent="center" className="Center">
            <FlexRow justifyContent="space-between">
                <SelectFolderProspecto
                    prospectoId={match.params.prospectoId}
                    vacanteId={match.params._id}
                    ></SelectFolderProspecto>
                <FlexRow>
                    <span>Plazo para cubrir vacante </span> 
                    <RemainingDays 
                        fecha={vacante.requisicion[0].datosGenerales.fecha}
                        tiempoSeleccion={vacante.tiempoSeleccion}
                        tiempoPause={vacante.requisicion[0].tiempoPause}/>
                </FlexRow>
            </FlexRow>
            <SectionHeader subtitle="Monitorea aquí el proceso de reclutamiento de tu prospecto">
                PROCESO PROSPECTO
            </SectionHeader>
            <FlexRow alignItems="start">
                <FormGroup
                    style={{maxWidth:'400px'}}>
                    <Input                     
                    type="text"                     
                    value={basicData.nombre || ''}
                    readOnly></Input>
                </FormGroup>
                <div style={{width:'20px'}}></div>
                <FlexColumn style={{maxWidth:'400px'}}>
                    <FormGroup>
                        <Input 
                        type="text"
                        value={vacante.requisicion[0].datosGenerales.nombrePuesto} 
                        readOnly></Input>
                    </FormGroup>
                    <FlexRow justifyContent="flex-end">
                        <FormGroup className="NoFlex">
                            <ProspectoVisibleToggler
                                visible={prospecto.visible}
                                prospectoId={prospecto._id}
                                vacanteId={prospecto.vacanteId}></ProspectoVisibleToggler>
                        </FormGroup>
                    </FlexRow>
                </FlexColumn>                                
            </FlexRow>      
            <br/>
            {
                prospecto.rejectedByClient && prospecto.status !== 'descartado' ? 
                <RejectedByClientPanel 
                    prospecto={prospecto}
                    onRejected={fetchProspecto}></RejectedByClientPanel> : null
            }      
            <br/>
            <h6 className="FontBlue">Modalidad Reclutador</h6>
            <br/>
            <Timeline 
                infoGeneral={prospecto.proceso.infoGeneral}
                pasos={prospecto.proceso.pasos}
                resultado={prospecto.proceso.resultado}></Timeline>
            <br/>
            <h6 className="FontBlue">Modalidad Candidato</h6>
            <br/>            
            <Timeline 
                infoGeneral={prospecto.proceso.infoGeneral}
                pasos={prospecto.proceso.pasos.filter(p => p.mostrarAProspecto)}
                resultado={prospecto.proceso.resultado}></Timeline>
            <br/>
            <SectionHeader textAlign="left">
                SUBE AQUÍ LOS RESULTADOS OBTENIDOS
            </SectionHeader>
            <Table style={{textAlign:'left'}}>
                <tbody>
                    <tr>
                        <td width="70%">Información General</td>
                        <td width="10%">
                        </td>
                        <td width="10%">
                            {/* <Button className="btn-white" onClick={handleStep('datosGenerales')}>Ver</Button>                                 */}
                            <StepButtonForCase p={prospecto.proceso.infoGeneral}></StepButtonForCase>
                        </td>                        
                        <td width="10%">
                            <MostrarAClienteToggler step={prospecto.proceso.infoGeneral}
                                prospectoId={prospecto._id}
                                vacanteId={prospecto.vacanteId}></MostrarAClienteToggler>
                        </td>
                    </tr>
                    {
                        prospecto.proceso.pasos.map(p => (
                            <tr key={p.nombre}>
                                <td width="70%">{p.uiNombre}</td>
                                <td width="10%">
                                    <ActiveButtonForCase p={p}></ActiveButtonForCase>                              
                                </td>
                                <td width="10%">
                                    <StepButtonForCase p={p}></StepButtonForCase>                              
                                </td>
                                <td width="10%">
                                    <MostrarAClienteToggler step={p}
                                        prospectoId={prospecto._id}
                                        vacanteId={prospecto.vacanteId}
                                        disabled={p.status !== 'done' && p.status !== 'current'}></MostrarAClienteToggler>
                                </td>
                            </tr>
                        ))
                    }
                    <tr>
                        <td width="70%" style={{paddingTop:'50px'}}>Otro</td>
                        <td width="10%"></td>
                        <td width="10%" style={{paddingTop:'50px'}}>
                            <StepButton className="btn-white"
                                title="Otro"
                                onComplete={handleCompleteModal}
                                stepForm={BasicStepFormFields}
                                stepProps={{
                                    prospectoId: prospecto._id,
                                    vacanteId: prospecto.vacanteId,
                                    stepName: 'otro'
                                }}>Ver</StepButton>                          
                        </td>
                        <td width="10%" style={{paddingTop:'50px'}}>
                        {
                            prospecto.proceso.otro ?
                            <MostrarAClienteToggler step={prospecto.proceso.otro}
                                prospectoId={prospecto._id}
                                vacanteId={prospecto.vacanteId}></MostrarAClienteToggler> : null
                        }                            
                        </td>
                    </tr>
                    {
                        prospecto.proceso.pasos.length > 0 &&
                        !prospecto.proceso.pasos.find(p => p.nombre === 'entrevistaPersonal') &&
                        prospecto.proceso.pasos.find(p => p.nombre ===  'verificacionPerfil').status !== 'done' ?
                        <tr>
                            <td width="70%" style={{paddingTop:'50px'}}>Entrevista personal</td>
                            <td width="10%"></td>
                            <td width="10%" style={{paddingTop:'50px'}}>                                                 
                            </td>
                            <td width="10%" style={{paddingTop:'50px'}}>   
                                <StepButton className="btn-white"
                                    title="Agregar entrevista personal"
                                    onComplete={handleCompleteModal}
                                    stepForm={AddEntrevistaPersonalFormFields}
                                    stepProps={{
                                        prospectoId: prospecto._id,
                                        vacanteId: prospecto.vacanteId
                                    }}>Agregar</StepButton>                          
                            </td>
                        </tr> : null
                    }
                </tbody>
            </Table>
            <br/>
            <SectionHeader textAlign="left">
                CALENDARIO PROSPECTO
            </SectionHeader>
            <br/>
            <ProspectoCalendar
                prospectoId={prospecto._id}
                vacanteId={prospecto.vacanteId}
                pasos={prospecto.proceso.pasos}
                onEventsLoaded={handleEventsLoaded}
                ></ProspectoCalendar>
            <br/>
            <ResultadoFinal
                paso={prospecto.proceso.resultado}
                prospectoId={prospecto._id}
                vacanteId={prospecto.vacanteId}
                onSaved={handleCompleteModal}
                ></ResultadoFinal>  
            <br/>   
            {
                prospecto.proceso.resultado.datos.resultado === 'aceptado' ?
                <React.Fragment>
                    <PostAsignacion 
                        prospectoId={prospecto._id}
                        vacanteId={prospecto.vacanteId}
                        events={postAsignacionEvents}></PostAsignacion>
                    <br/>
                    <CierreDefinitivo 
                        prospectoId={prospecto._id}
                        vacanteId={prospecto.vacanteId}></CierreDefinitivo>   
                </React.Fragment> : null
            }         
                
        </FlexColumn>
    )
}
