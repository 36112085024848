import React from 'react';
import HttpRequest from './HttpRequest'
import {Button} from 'reactstrap';

export default function DownloadFileButton({link, children, filePath, iconButton, ...props}) {

    const path = filePath || '/file/sign/';
    async function fetchSignedLink() {
        const response = await HttpRequest.get(path + link);
        return response.data.signed
    }

    async function handleClick() {
        const signed = await fetchSignedLink();
        window.open(HttpRequest.backend + '/file/signed/' + signed);
    }

    return (        
        iconButton ? 
        <Button>
        </Button> :
        <Button className="btn-blue" {...props}
            onClick={handleClick}>{children}</Button>
    )
}