import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label} from 'reactstrap';
import FastInput from '../shared/FastInput';
import FlexColumn from '../shared/FlexColumn';
import FlexRow from '../shared/FlexRow';
import FileInput from '../shared/FileInput';
import usePostHttpRequest from '../hooks/usePostHttpRequest';

/**
 * Forma basica para los pasos que no ocupan nada para marcarse como activos
 */
export default function BasicActiveFormFields({vacanteId, prospectoId, stepName, isOpen, onComplete, onCancel}) {

    const [file, setFile] = useState(null)
    const [working, doPost] = usePostHttpRequest();

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();   
        await doPost(`/prospectos/prospecto/${vacanteId}/${prospectoId}/proceso/current`, {
            stepName
        });        
        onComplete(stepName);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FlexColumn>
                <p>Marcar paso como actual en el proceso de reclutamiento</p>
                <FlexRow justifyContent='flex-end' className="FormButtons">
                    <Button type="submit" 
                        disabled={working}
                        color="primary">Aceptar</Button>
                    <Button color="secondary" 
                        disabled={working}
                        onClick={onCancel}>Cancelar</Button>
                </FlexRow>
            </FlexColumn>            
        </Form>
    )
}