import React, {useState, useEffect} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label} from 'reactstrap';
import FastInput from '../shared/FastInput';
import FlexColumn from '../shared/FlexColumn';
import FlexRow from '../shared/FlexRow';
import FileInput from '../shared/FileInput';
import HttpRequest from '../shared/HttpRequest';
import DownloadFileButton from '../shared/DownloadFileButton';
import { VideoModal, ShowVideoButton } from '../shared/VideoModal'

/**
 * Forma basica para los pasos que no tienen una forma definida
 * Muestra un campo de texto libre para comentarios
 * y permite adjuntar un archivo de cualquier tipo
 */
export default function BasicStepFormFields({vacanteId, prospectoId, stepName, isOpen, onComplete, onCancel}) {

    const [state, setState] = useState({
        comentario:'',
        archivo:null
    })
    const [file, setFile] = useState(null)
    const [working, setWorking] = useState(false);
    const [videos, setVideos] = useState(null)
    

    useEffect(() => {
        async function fetchData() {
            const response = await HttpRequest.get(`/prospectos/prospecto/${vacanteId}/${prospectoId}/proceso/${stepName}`);
            const {result, data} = response.data;
            if(result === 'ok') {
                const e = data.datos;
                setState({...e})
                if(data.videos && data.videos.files) {
                    setVideos(data.videos)
                }
            }
        }
        if(isOpen) {
            console.log('fetching telefonica: ' +  prospectoId)
            fetchData();
        }     
    }, [prospectoId, isOpen])

    function handleChange(name) {
        return function(event) {
            const target = event.target;
            setState({...state, [name]: target.type === 'checkbox' ? target.checked : target.value})
        }
    }

    function handleFileChange(file) {
        setFile(file)
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        
        setWorking(true);

        const formData = new FormData();
        formData.append('archivo', file || []);
        formData.append('stepData', JSON.stringify({
            stepName,
            stepData: {
                comentario: state.comentario
            }
        }));
        const response = await HttpRequest.postMultipart(`/prospectos/prospecto/${vacanteId}/${prospectoId}/proceso`, formData)        
        setWorking(false);
        onComplete(response.data);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FlexColumn>
                <FormGroup>
                    <Label>Comentario</Label>
                    <FastInput type="textarea" 
                        value={state.comentario}
                        onChange={handleChange('comentario')}/>
                </FormGroup>
                <br/>
                <FileInput maxSize={200} 
                    fileId="archivo"
                    fileUrl={state.archivo}
                    onChange={handleFileChange}></FileInput>
                {
                    videos ? videos.files.map(f => (
                        <FormGroup key={f}>
                            <ShowVideoButton link={f}>Ver video</ShowVideoButton>
                        </FormGroup> 
                    )) : null
                }
                <FlexRow justifyContent='flex-end' className="FormButtons">
                    <Button type="submit" 
                        disabled={working}
                        color="primary">Guardar</Button>
                    <Button color="secondary" 
                        disabled={working}
                        onClick={onCancel}>Cancelar</Button>
                </FlexRow>
            </FlexColumn>            
        </Form>
    )
}