import React, {useState} from 'react';
import FlexRow from '../shared/FlexRow';
import {  FormGroup, Label } from 'reactstrap';
import FastInput from '../shared/FastInput';
import styles from './RequisicionForm.module.css'

export default function RequisicionDatosGenerales({value, onChange, showDirty}) {

    const [dirty, setDirty] = useState(false);

    function handleChange(name) {
        return function(event) {
            setDirty(true);
            onChange({...value, [name]:event.target.value})
        }
    }   
    
    return (
        <div className={`${dirty && showDirty ? styles.sectionDirty : ''}`}>
            <h6>Datos Generales</h6>
            <FlexRow>
                <FormGroup>
                    <Label>Empresa</Label>
                    <FastInput type="text" 
                        value={value.empresa}
                        onChange={handleChange('empresa')}
                        required/>
                </FormGroup>
                <FormGroup>
                    <Label>Sucursal</Label>
                    <FastInput type="text" 
                        value={value.sucursal} onChange={handleChange('sucursal')} required/>
                </FormGroup>
            </FlexRow>   
            <FlexRow>
                <FormGroup>
                    <Label>Fecha de requisición</Label>
                    <FastInput placeholder="Fecha de Requisición" type="date" 
                        value={value.fecha}
                        onChange={handleChange('fecha')}
                        required/>
                </FormGroup>
                <FormGroup>
                    <Label>Número de vacantes</Label>
                    <FastInput type="number" value={value.numeroVacantes} onChange={handleChange('numeroVacantes')} required/>
                </FormGroup>
            </FlexRow>         
            <FlexRow>
                <FormGroup>
                    <Label>Motivo de la vacante</Label>
                    <FastInput type="select" placeholder="Motivo de la vacante" 
                    value={value.motivo}
                    onChange={handleChange('motivo')}
                    required>
                        <option>Nueva creación</option>
                        <option>Incremento de plantilla</option>
                        <option>Reemplazo</option>
                    </FastInput>
                </FormGroup>
                <FormGroup>
                    <Label>En caso de ser reemplazo, especificar la causa</Label>
                    <FastInput type="select"
                        value={value.causaReemplazo}
                        onChange={handleChange('causaReemplazo')}>
                        <option>N/A</option>
                        <option>Baja</option>
                        <option>Incapacidad</option>
                        <option>Vacaciones</option>
                        <option>Promoción</option>
                    </FastInput>
                </FormGroup>                    
            </FlexRow>
            <FormGroup>
                <Label>Nombre Puesto</Label>
                <FastInput placeholder="Nombre Puesto" 
                    value={value.nombrePuesto}
                    onChange={handleChange('nombrePuesto')}
                    required></FastInput>
            </FormGroup>
            <FormGroup>
                <Label>Objetivo del Puesto</Label>
                <FastInput placeholder="Objetivo del Puesto" type="textarea" 
                    value={value.objetivo}
                    onChange={handleChange('objetivo')}
                    required></FastInput>
            </FormGroup>
            <FlexRow>
                <FormGroup>
                    <Label>Coordinarse para entrevista con</Label>
                    <FastInput placeholder="Coordinarse para entrevista con" type="text" 
                        value={value.contacto}
                        onChange={handleChange('contacto')}
                        required></FastInput>
                </FormGroup>
                <FormGroup>
                    <Label>Cargo dentro de la empresa del contacto</Label>
                    <FastInput placeholder="Cargo dentro de la empresa del contacto" type="text" 
                        value={value.contactoPuesto}
                        onChange={handleChange('contactoPuesto')}
                        required></FastInput>
                </FormGroup>
            </FlexRow>                
            <FlexRow>
                <FormGroup>
                    <Label>Teléfono</Label>
                    <FastInput placeholder="Teléfono" type="text" 
                        value={value.contactoTelefono}
                        onChange={handleChange('contactoTelefono')}
                        required></FastInput>
                </FormGroup>
                <FormGroup>
                    <Label>Correo Electrónico</Label>
                    <FastInput placeholder="Correo Electrónico" type="email" 
                        value={value.contactoCorreo}
                        onChange={handleChange('contactoCorreo')}
                        required></FastInput>
                </FormGroup>
            </FlexRow>
            <FormGroup>
                <Label>Especificar proceso de contacto y envío de candidatos e información</Label>                    
                <FastInput placeholder="" type="select" 
                    value={value.procesoContacto}
                    onChange={handleChange('procesoContacto')}
                    required>
                    <option>Por medio de correo</option>
                    <option>Por medio de llamadas</option>
                    <option>Correo y teléfono</option>
                </FastInput>
            </FormGroup>
            <FormGroup>
                <Label>Dirección completa de lugar de trabajo</Label>
                <FastInput placeholder="Dirección completa de lugar de trabajo" type="text" 
                    value={value.direccion}
                    onChange={handleChange('direccion')}
                    required></FastInput>
            </FormGroup>
        </div>
    )
}