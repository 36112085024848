import React, {useState, useCallback} from 'react';
import HttpRequest from '../shared/HttpRequest'

export default function useFetch(initialState) {
    const [working, setWorking] = useState(false);
    const [data, setData] = useState(initialState);

    const doFetch = useCallback(async (path, options) => {
        setWorking(true);
        const response = await HttpRequest.get(path, options)
        setData(response.data);
        setWorking(false);    
    }, [])

    return [working, data, doFetch]
}