import React, {useState} from 'react';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label} from 'reactstrap';
import FastInput from '../shared/FastInput';
import FlexColumn from '../shared/FlexColumn';
import FlexRow from '../shared/FlexRow';
import FileInput from '../shared/FileInput';
import HttpRequest from '../shared/HttpRequest';

/**
 * Forma para agregar el paso de entrevista personal
 * al proceso de prospecto
 */
export default function AddEntrevistaPersonalFormFields({vacanteId, prospectoId, stepName, isOpen, onComplete, onCancel}) {

    const [working, setWorking] = useState(false);
    
    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        
        setWorking(true);
        const response = await HttpRequest.post(`/prospectos/prospecto/${vacanteId}/${prospectoId}/addPersonal`)
        setWorking(false);
        onComplete(response.data);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <p>
                Se agregará el paso de entrevista personal después de entrevista digital 
                al proceso de reclutamiento
            </p>
            <FlexRow justifyContent='flex-end' className="FormButtons">
                <Button type="submit" 
                    disabled={working}
                    color="primary">Guardar</Button>
                <Button color="secondary" 
                    disabled={working}
                    onClick={onCancel}>Cancelar</Button>
            </FlexRow>
        </Form>
    )
}