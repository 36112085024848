import React, {useState, useEffect, useCallback} from 'react';
import moment from 'moment';
import 'moment/locale/es';
import FlexRow from '../shared/FlexRow';
import FlexColumn from '../shared/FlexColumn';
import CalendarEventDropdown from '../shared/CalendarEventDropdown';
import {CALENDAR_EVENT_TYPES} from '../shared/Utils'
import EventsCalendar from '../shared/EventsCalendar';
import CalendarAuthorization from '../shared/CalendarAuthorization';
import HttpRequest from '../shared/HttpRequest';

moment.locale('es');

const EVENT_TYPES = Object.keys(CALENDAR_EVENT_TYPES)

export default function ProspectoCalendar({prospectoId, vacanteId, pasos, onEventsLoaded}) {

    const [selectedDate, setSelectedDate] = useState(new Date());
    const [month, setMonth] = useState(new Date().getMonth() + 1)
    const [events, setEvents] = useState({});
    const [unrelatedEvents, setUnrelatedEvents] = useState({})
    const [eventTypes, setEventTypes] = useState([])
    const [isCalendarAuthorized, setCalendarAuthorized] = useState(null);

    useEffect(() => {
        setEventTypes(pasos.find(p => p.nombre === 'entrevistaPersonal') ? 
            EVENT_TYPES : EVENT_TYPES.filter(e => e !== 'ENTREVISTA_PERSONAL'))
    }, [pasos])

    useEffect(() => {
        async function fetchEvents() {
            const response = await HttpRequest.get(`/eventos/mes/${month}`);
            setUnrelatedEvents(events => {
                const newEvents = {...events};
                for(let e of response.data) {
                    const label = moment(e.start.dateTime).format('DD/MM/YYYY')
                    if(!newEvents[label]) {
                        newEvents[label] = [];
                    }
                    const exists = newEvents[label].find(ce => ce._id === e.id);
                    if(exists) {
                        continue;
                    }
                    newEvents[label].push({
                        _id: e.id,
                        eventType:e.summary,
                        status:e.status                  
                    })
                }
                return newEvents;
            })
        }
        fetchEvents()
    }, [month])

    const handleCalendarAuthorizedChanged = useCallback(function (authorized) {
        setCalendarAuthorized(authorized)
    }, [])

    function handleDateChange(date) {
        setSelectedDate(date);
    }    

    function handleMonthChange(month) {
        setMonth(month + 1)
    }

    const handleEventsLoaded = useCallback(function(evs) {
        if(evs && evs.length > 0) {
            setEvents(events => {
                console.log(events)
                const newEvents = {...events};
                for(let e of evs) {
                    const label = moment(e.google.start.dateTime).format('DD/MM/YYYY')
                    if(!newEvents[label]) {
                        newEvents[label] = [];
                    }
                    const exists = newEvents[label].find(ce => ce._id === e._id);
                    if(exists) {
                        continue;
                    }
                    newEvents[label].push({
                        _id: e._id,
                        eventType:e.eventType,
                        status:e.status                  
                    })
                }
                console.log(newEvents)
                return newEvents;
            })            
            onEventsLoaded({events:evs})
        }        
    }, [])   

    function handleEventDeleted(event) {
        // if(events[event.label]) {            
        //     const newEvents = {...events};
        //     newEvents[event.label] = newEvents[event.label].filter(ev => ev._id !== event._id);
        //     setEvents(newEvents);
        // }
    }
    
 
    return (
        <FlexColumn>
            <CalendarAuthorization 
                isAuthorized={isCalendarAuthorized}
                onAuthorizedChanged={handleCalendarAuthorizedChanged}></CalendarAuthorization>
            {
                isCalendarAuthorized === true ?
                <FlexRow justifyContent="start">
                <EventsCalendar
                    prospectoId={prospectoId}
                    vacanteId={vacanteId}
                    onMonthChange={handleMonthChange}
                    onDateChange={handleDateChange}
                    value={selectedDate}
                    events={events}
                    unrelatedEvents={unrelatedEvents}
                ></EventsCalendar>
                <FlexColumn className="CalendarButtons">
                    {
                        eventTypes.map(et => (
                            <CalendarEventDropdown 
                                key={et}
                                prospectoId={prospectoId}
                                vacanteId={vacanteId}
                                selectedDate={selectedDate}
                                onEventsLoaded={handleEventsLoaded}
                                onEventDeleted={handleEventDeleted}
                                type={CALENDAR_EVENT_TYPES[et]}></CalendarEventDropdown>
                        ))
                    }
                </FlexColumn>
            </FlexRow>  : null
            }
            
        </FlexColumn>
                    
    )
}