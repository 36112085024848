import React, {useState} from 'react';
import FlexRow from '../shared/FlexRow';
import {  FormGroup, Label, Button, Input } from 'reactstrap';
import FastInput from '../shared/FastInput';
import styles from './RequisicionForm.module.css'

export default function RequisicionPerfil({value, onChange, showDirty}) {

    const [dirty, setDirty] = useState(false);

    function handleChange(name) {
        return function(event) {
            setDirty(true);
            onChange({...value, [name]:event.target.value})
        }
    }   

    function handleInputInArrayChange(arrayName, name, i) {
        return function(event) {
            setDirty(true);
            const data = [...value[arrayName]];
            data[i][name] = event.target.value;
            onChange({...value, [arrayName]:data})
        }            
    }   

    function handleAddToArray(name) {
        return function() {
            setDirty(true);
            onChange({...value, [name]:[...value[name], {
                nombre: '',
                nivel: 'Básico'
            }]})
        }            
    }

    return (
        <div className={`${dirty && showDirty ? styles.sectionDirty : ''}`}>
            <h6>Perfil del Puesto</h6>
            <FlexRow>
                <FormGroup>
                    <Label>Edad</Label>
                    <FastInput placeholder="Edad" type="text" 
                        value={value.edad}
                        onChange={handleChange('edad')}
                        required></FastInput>
                </FormGroup>
                <FormGroup>
                    <Label>Sexo</Label>
                    <FastInput placeholder="Sexo" type="select" 
                        value={value.sexo}
                        onChange={handleChange('sexo')}
                        required>
                        <option>M</option>
                        <option>F</option>
                        <option>Indistinto</option>
                    </FastInput>
                </FormGroup>
                <FormGroup>
                    <Label>Estado Civil</Label>
                    <FastInput placeholder="Estado Civil" type="select" 
                        value={value.estadoCivil}
                        onChange={handleChange('estadoCivil')}
                        required>
                        <option>Soltero</option>
                        <option>Casado</option>
                        <option>Indistinto</option>
                    </FastInput>
                </FormGroup>
            </FlexRow>
            <FormGroup>
                <Label>Selecciona el nivel académico mínimo requerido para el puesto</Label>
                <FastInput placeholder="" type="select" 
                    value={value.nivelAcademico}
                    onChange={handleChange('nivelAcademico')}
                    required>
                    <option>Primaria</option>
                    <option>Secundaria</option>
                    <option>Preparatoria</option>
                    <option>Técnico</option>
                    <option>Licenciatura y/o Ingeniería</option>
                    <option>Maestría</option>
                </FastInput>
            </FormGroup>
            <FormGroup>
                <Label>Estatus de nivel académico requerido para el puesto</Label>
                <FastInput placeholder="" type="select" 
                    value={value.nivelAcademicoEstatus}
                    onChange={handleChange('nivelAcademicoEstatus')}
                    required>
                    <option>Trunco</option>
                    <option>Pasante</option>
                    <option>Titulado</option>
                </FastInput>
            </FormGroup>
            <FlexRow>
                <FormGroup>
                    <Label>Requiere experiencia previa</Label>
                    <Input placeholder="" type="select" 
                        value={value.experienciaPrevia}
                        onChange={handleChange('experienciaPrevia')}
                        required>
                        <option>Si</option>
                        <option>No</option>
                    </Input>
                </FormGroup>                
            </FlexRow>
            {
                value.experienciaPrevia === 'Si' ?
                <React.Fragment>
                    <FormGroup>
                        <Label>Tiempo de experiencia previa</Label>
                        <FastInput placeholder="Tiempo de experiencia previa" type="text" 
                            value={value.experienciaPreviaTiempo}
                            onChange={handleChange('experienciaPreviaTiempo')}
                            required></FastInput>
                    </FormGroup>
                    <FormGroup>
                        <Label>Especifica la experiencia previa deseable</Label>
                        <FastInput placeholder="Especifica la experiencia previa deseable" type="textarea" 
                            value={value.experienciaPreviaDetalle}
                            onChange={handleChange('experienciaPreviaDetalle')}
                            required></FastInput>
                    </FormGroup>
                </React.Fragment> : null
            }
            <br/>
            <div>Software requerido</div>
            {
                value.software.map((s, i) => (
                    <FlexRow key={i}>
                        <FormGroup>
                            <Label>Nombre</Label>
                            <FastInput placeholder="Software requerido" type="text" 
                                value={s.nombre}
                                onChange={handleInputInArrayChange('software', 'nombre', i)}
                                required></FastInput>
                        </FormGroup>
                        <FormGroup>
                            <Label>Nivel</Label>
                            <FastInput placeholder="Nivel" type="select" 
                                value={s.nivel}
                                onChange={handleInputInArrayChange('software', 'nivel', i)}
                                required>
                                <option>Básico</option>
                                <option>Intermedio</option>
                                <option>Avanzado</option>
                            </FastInput>
                        </FormGroup>                    
                    </FlexRow>
                ))
            }                
            <Button color="link" onClick={handleAddToArray('software')}>+ Agregar campo</Button>
            <br/>
            <div>Idioma requerido</div>
            {
                value.idioma.map((idioma, i) => (
                    <FlexRow key={i}>
                        <FormGroup>
                            <Label>Nombre</Label>
                            <FastInput placeholder="Idioma requerido" type="text" 
                                value={idioma.nombre}
                                onChange={handleInputInArrayChange('idioma', 'nombre', i)}
                                required></FastInput>
                        </FormGroup>
                        <FormGroup>
                            <Label>Nivel</Label>
                            <FastInput placeholder="Nivel" type="select" 
                                value={idioma.nivel}
                                onChange={handleInputInArrayChange('idioma', 'nivel', i)}
                                required>
                                <option>Básico</option>
                                <option>Intermedio</option>
                                <option>Avanzado</option>
                            </FastInput>
                        </FormGroup>
                    </FlexRow>
                ))
            }     
            <Button color="link" onClick={handleAddToArray('idioma')}>+ Agregar campo</Button>                   
            <FlexRow>
                <FormGroup>
                    <Label>El puesto requiere de disponiblidad de</Label>
                    <FastInput placeholder="" type="select" 
                        value={value.disponibilidad}
                        onChange={handleChange('disponibilidad')}
                        required>
                        <option>Ninguna</option>
                        <option>Automóvil</option>
                        <option>Viajar</option>
                        <option>Vivir cerca</option>             
                        <option>Otro</option>               
                    </FastInput>
                </FormGroup>    
                <FormGroup>
                    <Label>Otro (especificar)</Label>
                    <FastInput placeholder="" type="text" 
                        value={value.disponibilidadOtro}
                        onChange={handleChange('disponibilidadOtro')}></FastInput>
                </FormGroup>
            </FlexRow>
            <FlexRow>
                <FormGroup>
                    <Label>Documentos adicionales</Label>
                    <FastInput placeholder="" type="select" 
                        value={value.documentosAdicionales}
                        onChange={handleChange('documentosAdicionales')}
                        required>
                        <option>N/A</option>
                        <option>Licencia de manejo</option>
                        <option>Pasaporte</option>
                        <option>VISA</option>                            
                        <option>Otro</option>
                    </FastInput>
                </FormGroup>    
                <FormGroup>
                    <Label>Otro (especificar)</Label>
                    <FastInput placeholder="" type="text" 
                        value={value.documentosAdicionalesOtro}
                        onChange={handleChange('documentosAdicionalesOtro')}></FastInput>
                </FormGroup>
            </FlexRow>
            <FormGroup>
                <Label>Favor de especificar el tipo de licencia de manejo requerida</Label>
                <FastInput placeholder="" type="select" 
                    value={value.tipoLicencia}
                    onChange={handleChange('tipoLicencia')}
                    required>
                    <option>Ninguna</option>
                    <option>Motociclista</option>
                    <option>Automovilista (vehículos de hasta 15 plazas)</option>
                    <option>Chofer (vehículos de carga de hasta 3 1/2 toneladas)</option>
                    <option>Chofer foráneo (vehículos de carga de más de 3 1/2 toneladas que manejen en carretera)</option>
                    <option>Chofer de servicios públicos</option>
                </FastInput>
            </FormGroup>
        </div>
    )

}