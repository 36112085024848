import React from 'react';
import {TabPane, FormGroup, Label, Button, Input, Table} from 'reactstrap'
import FastInput from '../shared/FastInput';
import FlexColumn from '../shared/FlexColumn';
import FlexRow from '../shared/FlexRow';
import ReferenciaCalificacion from './ReferenciaCalificacion';

export default function ModalReferenciaTabPane({value, tabId, onRemoveClick, onChange, onCalificacionChange}) {

    return (
        <TabPane tabId={tabId}>
            {
                tabId > 0 ?
                <Button onClick={onRemoveClick}>Eliminar</Button> : null
            }
            <FlexColumn>
                <FormGroup>
                    <Label>Fecha</Label>
                    <FastInput type="date"
                        value={value.fecha}
                        onChange={onChange('fecha')}/>
                </FormGroup>
                <FlexRow>
                    <FormGroup>
                        <Label>Empresa</Label>
                        <FastInput type="text"
                            value={value.empresa}
                            onChange={onChange('empresa')}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Relación laboral</Label>
                        <FastInput type="text"
                            value={value.relacionLaboral}
                            onChange={onChange('relacionLaboral')}/>
                    </FormGroup>
                </FlexRow>
                <FlexRow>
                    <FormGroup>
                        <Label>Telefono</Label>
                        <FastInput type="text"
                            value={value.telefono}
                            onChange={onChange('telefono')}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Tiempo laborado</Label>
                        <FastInput type="text"
                            value={value.tiempoLaborado}
                            onChange={onChange('tiempoLaborado')}/>
                    </FormGroup>
                </FlexRow>
                <FlexRow>
                    <FormGroup>
                        <Label>Contacto</Label>
                        <FastInput type="text"
                            value={value.contacto}
                            onChange={onChange('contacto')}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Causa de baja</Label>
                        <FastInput type="text"
                            value={value.causaBaja}
                            onChange={onChange('causaBaja')}/>
                    </FormGroup>
                </FlexRow>      
                <FlexRow>
                    <FormGroup>
                        <Label>Cargo</Label>
                        <FastInput type="text"
                            value={value.cargo}
                            onChange={onChange('cargo')}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Salario</Label>
                        <FastInput type="text"
                            value={value.salario}
                            onChange={onChange('salario')}/>
                    </FormGroup>
                </FlexRow>          
                <FormGroup>
                    <Label>Desempeño</Label>
                    <FastInput type="textarea"
                        value={value.desempeno}
                        onChange={onChange('desempeno')}/>
                </FormGroup>
                <br/>
                {
                    value.calificaciones.map(c => (
                        <ReferenciaCalificacion 
                            key={c.name}
                            tabId={tabId}
                            value={c.value} 
                            name={c.name} 
                            onChange={onCalificacionChange(c.name)}></ReferenciaCalificacion>
                    ))
                }
                <br/>
                <FormGroup>
                    <Label>¿Lo contrataría nuevamente?</Label>
                    <FastInput type="textarea"
                        value={value.contratariaNuevamente}
                        onChange={onChange('contratariaNuevamente')}/>
                </FormGroup>
                <FormGroup>
                    <Label>Observaciones</Label>
                    <FastInput type="textarea"
                        value={value.observaciones}
                        onChange={onChange('observaciones')}/>
                </FormGroup>
            </FlexColumn>                      
        </TabPane>
    )

}