import moment from 'moment';

export function calcFechaLimite(createdAt, diasSeleccion, tiempoPause) {
    return moment(createdAt)
        .add(diasSeleccion, 'days')
        .add(tiempoPause ? tiempoPause : 0, 'days')
        .format('YYYY/MM/DD')
}

export function formatDate(date) {
    return moment(date).format('YYYY-MM-DD');
}

export const CALENDAR_EVENT_TYPES = {
    ENTREVISTA_TELEFONICA: 'entrevistaTelefonica',
    ENTREVISTA_DIGITAL: 'entrevistaDigital',
    ENTREVISTA_PERSONAL: 'entrevistaPersonal',
    EVALUACION_PSICOMETRICA: 'evaluacionPsicometrica',
    ESTUDIO_SOCIOECONOMICO: 'estudioSocioeconomico',
    ENTREVISTA_EMPRESA: 'entrevistaConEmpresa',
    POST_ASIGNACION: 'postAsignacion',
    CIERRE_DEFINITIVO: 'cierreDefinitivo' 
}

export const CALENDAR_EVENT_LABELS = {
    entrevistaTelefonica: 'Entrevista telefónica',
    entrevistaDigital: 'Entrevista digital',
    evaluacionPsicometrica: 'Evaluación psicométrica',
    estudioSocioeconomico: 'Estudio socioeconómico',
    entrevistaConEmpresa: 'Entrevista con empresa',
    postAsignacion: 'Post asignación',
    cierreDefinitivo: 'Cierre definitivo',
    entrevistaPersonal: 'Entrevista personal'
}