import axios from 'axios';

const backend = 'https://reclutamiento.api.integrarh.mx';
// const backend = 'http://localhost:3100'

function get(path, options) {
    return axios.get(backend + path, options);
}

function postMultipart(path, data) {
    return axios.post(backend + path, data, {
        headers: {
            'Content-Type':'multipart/form-data'
        },
        onUploadProgress: e => {
            let progress = Math.floor((e.loaded * 100) / e.total);
            console.log(progress)
        }
    })
}

function post(path, data) {
    return axios.post(backend + path, data);
}

export default {
    backend, get, post, postMultipart
}
