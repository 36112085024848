import React, {useState} from 'react';
import {Button, Form, FormGroup, Label} from 'reactstrap';
import FastInput from '../shared/FastInput';
import FlexColumn from '../shared/FlexColumn';
import FlexRow from '../shared/FlexRow';
import usePostHttpRequest from '../hooks/usePostHttpRequest';

/**
 * Solicita una clave para que el prospecto pueda realizar
 * su examen psicometrico
 * 
 * Si la evaluacion se encuentra en estatus de 'waitingConfirmation' 
 * permite reiniciar el proceso, por ejemplo:
 * la clave ya no sirve
 * se equivocó el reclutador al adjuntar el archivo
 * el prospecto realizara la evaluacion de nuevo
 * etc
 */
export default function PsicometricoActiveFormFields({vacanteId, prospectoId, stepName, status, onComplete, onCancel}) {

    const [state, setState] = useState({
        clave:''
    })
    const [working, doPost] = usePostHttpRequest();

    function handleChange(name) {
        return function(event) {
            const target = event.target;
            setState({...state, [name]: target.type === 'checkbox' ? target.checked : target.value})
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();   
        await doPost(`/prospectos/prospecto/${vacanteId}/${prospectoId}/proceso/current`, {
            stepName,
            requisito: {
                clave:state.clave
            }
        });        
        onComplete(stepName);
    }

    return (
        <Form onSubmit={handleSubmit}>
            <FlexColumn>
                {
                    status === 'waitingConfirmation' || status === 'current' ?
                    <p>
                        Al dar click en reiniciar podrás solicitar al prospecto 
                        que realice nuevamente su evaluación o que la confirme una vez más
                    </p> : null
                }
                <FormGroup>
                    <Label>Clave para realizar evaluación</Label>
                    <FastInput 
                        value={state.clave}
                        onChange={handleChange('clave')}
                        required/>
                </FormGroup>
                <br/>
                <FlexRow justifyContent='flex-end' className="FormButtons">
                    <Button type="submit" 
                        disabled={working}
                        color="primary">
                        {
                            status === 'waitingConfirmation' || status === 'current' ?
                            'Reiniciar' : 'Guardar'
                        }    
                    </Button>
                    <Button color="secondary" 
                        disabled={working}
                        onClick={onCancel}>Cancelar</Button>
                </FlexRow>
            </FlexColumn>            
        </Form>
    )
}