import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Date from '../shared/Date';
import {Collapse} from 'reactstrap'
import useToggle from '../hooks/useToggle';
import styles from './ListVacanteItem.module.css';
import { faChevronDown, faChevronUp, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import FlexRow from '../shared/FlexRow'
import SaveAsButton from '../shared/SaveAsButton';


export default function ReqHistory({requisicion}) {

    const [showHistory, toggleHistory] = useToggle(false)

    function handleClickTitle(event) {
        event.stopPropagation();
        toggleHistory();
    }

    return (        
            requisicion.history.length > 1 ?
            <React.Fragment>
                <FlexRow onClick={handleClickTitle} style={{marginBottom:'10px'}}>
                    <div className={styles.exclamationIcon}>
                        <FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon>
                    </div>
                    <div>Esta requisición ha tenido modificaciones</div>
                    <div className={styles.collapseIcon}>
                        <FontAwesomeIcon icon={showHistory ? faChevronUp : faChevronDown}></FontAwesomeIcon>
                    </div>
                </FlexRow>
                <Collapse isOpen={showHistory}>            
                    <table style={{width:'100%'}}>
                        <colgroup>
                           <col span="1" style={{width: '20%'}}/>
                           <col span="1" />
                           <col span="1" style={{width: '200px'}}/>
                        </colgroup>
                        <thead className={styles.historialHeader}>
                            <tr>
                                <td>Historial de ediciones</td>
                                <td>Editado por</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>  
                            {
                                requisicion.history.map((r,i) => (
                                    <tr className={styles.historialRow} key={i}>
                                        <td>
                                            <Date>{r.date}</Date>
                                        </td>
                                        <td>{r.modificadoPor ? r.modificadoPor.nombre : ''}</td>
                                        <td>
                                        <SaveAsButton
                                            style={{marginLeft:'10px'}}
                                            iconButton={true}
                                            link={`/requisiciones/${requisicion._id}/${i}`}
                                            title="requisicion.pdf">DESCARGAR PDF</SaveAsButton>
                                        </td>
                                    </tr>
                                ))
                            }                                                  
                        </tbody>
                    </table>
                </Collapse>
            </React.Fragment> : null
    )
}