import React, {useState, useEffect} from 'react'

export default function Timeline({infoGeneral, pasos, resultado}) {

    const [width, setWidth] = useState('');

    useEffect(() => {
        setWidth((95/(pasos.length + 2)) + '%');
    }, [pasos.length])

    function isDone(p) {
        return p.status === 'done';
    }

    function isCurrent(p) {
        return p.status === 'current';
    }

    return (
        <div>
            <ul className="Timeline">
                <li className="active"  style={{width}}>Información General</li>
                {                    
                    pasos.map(p => (
                        <li key={p.nombre}
                            className={`${isDone(p) ? 'active' : ''} ${isCurrent(p) ? 'current' : ''}`}
                            style={{width}}>{p.uiNombre}
                        </li>
                    ))
                }
                <li className={`${isCurrent(resultado) ? 'current' : ''}`}  style={{width}}>Resultado</li>
            </ul>
        </div>
    )

}