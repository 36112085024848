import React from 'react';
import { Collapse, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withRouter } from 'react-router-dom'


function SideMenuItem({title, icon, children, value, selected, onSelect}) {

    function handleClick() {
        onSelect(value);
    }

    return (
        <div>
            <div onClick={handleClick} className={`container SideMenuItemTitle ${selected ? 'FontBlue' : 'FontGray'}`}>
                <div className="row align-items-center">
                    <div className="col-2" style={{fontSize:'25px'}}>
                        <FontAwesomeIcon icon={icon}></FontAwesomeIcon>                      
                    </div>
                    <div className="col">
                        <div>{title}</div>
                    </div>
                </div>            
            </div>
            <Collapse isOpen={selected} className="container FontGray">
                <div className="row align-items-center">
                    <div className="col-2">               
                    </div>
                    <div className="col">
                        {children}
                    </div>
                </div>    
            </Collapse>
        </div>
    )
}

export default withRouter(SideMenuItem);