
import React, {useState, useEffect} from 'react';
import HttpRequest from '../shared/HttpRequest'
import axios from 'axios';

const jwt = localStorage.getItem('jwt');

export default function useSession() {
    const [userData, setUserData] = useState({
        nombre: ''
    })    

    if(axios.interceptors.request.handlers.length == 0 && jwt) {
        console.log('ADDING INTERCEPTOR');            
        axios.interceptors.request.use(
            (config) => {
            config.headers['Authorization'] = `Bearer ${ jwt }`;
            return config;
            }, 

            (error) => {
            return Promise.reject(error);
            }
        );
    } else if(!jwt) {
        console.log('REMOVING INTERCEPTOR')
        axios.interceptors.request.handlers = [];
    }

    useEffect(() => {
        async function fetchUserData() {
            const response = await HttpRequest.get('/session/userData');
            setUserData({...response.data})
        }
        if(jwt) {
            fetchUserData();
        }        
    }, [])

    function logout() {
        localStorage.removeItem('jwt');
        window.location.href = '/login'
    }

    function login(jwt) {
        localStorage.setItem('jwt', jwt)
        window.location.href = '/'
    }

    return [userData, login, logout]

}
