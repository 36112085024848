import React, {useState, useEffect} from 'react';
import {Button, Form, FormGroup,
    TabContent, Nav, NavItem, NavLink} from 'reactstrap';
import FlexRow from '../shared/FlexRow';
import HttpRequest from '../shared/HttpRequest';
import {formatDate} from '../shared/Utils'
import ModalReferenciaTabPane from './ModalReferenciaTabPane'

const newRef = {
    fecha: formatDate(new Date()),
    empresa: '',
    relacionLaboral:'',
    telefono: '',
    tiempoLaborado:'',
    contacto: '',
    causaBaja:'',
    cargo: '',
    salario: '',
    desempeno:'',
    observaciones: '',
    contratariaNuevamente:'',
    calificaciones: [{
        name: 'iniciativa',
        value: 'bueno'
    }, {
        name: 'puntualidad',
        value: 'bueno'
    }, {
        name: 'asistencia',
        value: 'bueno'
    }, {
        name: 'adaptabilidad',
        value: 'bueno'
    }, {
        name: 'calidadTrabajo',
        value: 'bueno'
    }, {
        name: 'honradez',
        value: 'bueno'
    }, {
        name: 'colaboracion',
        value: 'bueno'
    }, {
        name:'responsabilidad',
        value: 'bueno'
    }, {
        name: 'proyeccion',
        value: 'bueno'
    }, {
        name: 'actitudJefe',
        value: 'bueno'
    }, {
        name: 'actitudCompaneros',
        value: 'bueno'
    }, {
        name: 'actitudSubordinados',
        value: 'bueno'
    }]
}

export default function ModalReferencias({isOpen, onCancel, onComplete, vacanteId, prospectoId}) {

    const [tab, setTab] = useState(0);
    const [referencias, setReferencias] = useState([JSON.parse(JSON.stringify(newRef))])
    const [working, setWorking] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const response = await HttpRequest.get(`/prospectos/prospecto/${vacanteId}/${prospectoId}/proceso/referenciasLaborales`);
            const {result, data} = response.data;
            if(result === 'ok') {
                // const e = data.datos;
                // e.fecha = formatDate(data.datos.fecha);
                if(data.datos.length) {
                    const refs = data.datos.map(r => ({...r, fecha: formatDate(r.fecha)}))
                    setReferencias(data.datos);
                }                
            }
        }
        if(isOpen) {
            console.log('fetching telefonica: ' +  prospectoId)
            fetchData();
        }     
    }, [prospectoId, isOpen])

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        setWorking(true);
        const formData = new FormData();
        formData.append('stepData', JSON.stringify({
            stepName:'referenciasLaborales',
            stepData: referencias
        }));
        const response = await HttpRequest.postMultipart(`/prospectos/prospecto/${vacanteId}/${prospectoId}/proceso`, formData)        
        setWorking(false);
        onComplete(response.data);
    }

    function handleRemoveFromArray(index) {
        return function(event) {
            setReferencias(referencias.filter((v, i) => i != index))
            if(tab === index) {
                setTab(0)
            }
        }
    }

    function handleAddToArray() {
        setReferencias([...referencias, JSON.parse(JSON.stringify(newRef))])
        setTab(referencias.length)
    }

    function handleChange(index) {
        return function(name) {
            return function(event) {
                const refs = [...referencias];
                refs[index] = {...refs[index], [name]:event.target.value}                                
                setReferencias(refs)
            }
        }
    }

    function handleCalificacionChange(index) {
        return function(name) {
            return function(event) {
                const refs = [...referencias];
                const calificaciones = refs[index].calificaciones;
                calificaciones.find(c => c.name == name).value = event.target.value;
                setReferencias(refs);
            }
        }
    }

    return (
        <Form onSubmit={handleSubmit}>
            <Nav tabs>
                {
                    referencias.map((r, i) => (
                        <NavItem key={i}>
                            <NavLink
                            onClick={() => setTab(i)}
                            className={tab == i ? 'active' : ''}>{i + 1}</NavLink>
                        </NavItem>
                    ))
                }                
                <NavItem>
                    <NavLink
                        onClick={handleAddToArray}
                    >Agregar</NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={tab}>
                {
                    referencias.map((r,i) => (
                        <ModalReferenciaTabPane 
                            key={i}
                            tabId={i} 
                            value={r} 
                            onRemoveClick={handleRemoveFromArray(i)}
                            onChange={handleChange(i)}
                            onCalificacionChange={handleCalificacionChange(i)}></ModalReferenciaTabPane>
                    ))
                }
            </TabContent>
            <FlexRow justifyContent='flex-end' className="FormButtons">
                <Button type="submit" color="primary" disabled={working}>Guardar</Button>
                <Button color="secondary" onClick={onCancel} disabled={working}>Cancelar</Button>
            </FlexRow>            
        </Form>
    )

}