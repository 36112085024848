import React, {useState, useEffect} from 'react';
import {Button} from 'reactstrap';
import usePostHttpRequest from '../hooks/usePostHttpRequest';

export default function MostrarAClienteToggler({vacanteId, prospectoId, step, disabled, ...props}) {

    const [mostrarACliente, setMostrarACliente] = useState(step.mostrarACliente);
    const [working, doPost] = usePostHttpRequest();

    useEffect(() => {
        setMostrarACliente(step.mostrarACliente);
    }, [step.mostrarACliente])

    async function handleClick() {
        await doPost(`/prospectos/prospecto/${vacanteId}/${prospectoId}/paso/mostrarACliente`, {
            stepName: step.nombre,
            mostrarACliente: !mostrarACliente
        })
        setMostrarACliente(!mostrarACliente);
    }

    return (
        <Button {...props} className="btn-white"
            disabled={disabled || working}
            onClick={handleClick}>
            {mostrarACliente ? 'Mostrando' : 'Mostrar'}
        </Button>
    )
    
}