import React, {useState} from 'react';
import CheckboxGroup from '../shared/CheckboxGroup';
import styles from './RequisicionForm.module.css'

const contactosOptions = [
    'Listado de proveedores', 'Acceso a cuentas bancarias', 'Claves de acceso', 'Lista de clientes', 
    'Plan de trabajo', 'Listado de cuentas por cobrar', 'Directorio tel. de la empresa', 'Email de colaboradores',
    'Expediente clientes', 'Otros'
]

export default function RequisicionContactos({value, onChange, showDirty}) {

    const [dirty, setDirty] = useState(false);


    function handleChange(value) {
        setDirty(true);
        onChange(value)
    }

    return (
        <div className={`${dirty && showDirty ? styles.sectionDirty : ''}`}>
            <h6>Contactos / Administrativo</h6>
            <CheckboxGroup options={contactosOptions} 
                onChange={handleChange}
                value={value}></CheckboxGroup>
        </div>
    )

}