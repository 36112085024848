import React, {useState} from 'react';
import useToggle from '../hooks/useToggle'
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'

export default function ConfirmationDialog({title, body, children, skip}) {

    const [isOpen, toggle] = useToggle(false);
    const [callback, setCallback] = useState(null);

    function show(handler) {
        return function(event) {
            event.preventDefault();
            event.stopPropagation();
            if(skip) {
                handler();
            } else {
                setCallback(() => handler);
                toggle();
            }            
        }
    }

    function handleConfirm() {
        callback();
        toggle();
    }

    return (
        <React.Fragment>
            {children(show)}
            <Modal isOpen={isOpen}>
                <ModalHeader toggle={toggle}>
                    {title}
                </ModalHeader>
                <ModalBody>
                    {body}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleConfirm}>Aceptar</Button>
                    <Button color="seconday" onClick={toggle}>Cancelar</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )

}