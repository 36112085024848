import React, {useState} from 'react';
import CheckboxGroup from '../shared/CheckboxGroup';
import styles from './RequisicionForm.module.css'

const caracteristicasDelPuestoOptions = [
    'Actitud Positiva', 'Capacidad para priorizar', 'Voluntad para aprender',
    'Analítica', 'Comunicación escrita', 'Creatividad', 'Apego a políticas y procedimientos',
    'Comunicación oral', 'Decisivo', 'Asertivo', 'Comunicación personal', 'Deseo para indicar',
    'Asume riesgos', 'Versatilidad', 'Eficiente', 'Capacidad de transformación', 'Vision',
    'Empatía', 'Energía', 'Organizado y disciplinado', 'Proactivo', 'Establece metas', 
    'Orientado al equipo', 'Responsable', 'Honesto', 'Perceptivo', 'Toma de decisiones',
    'Mantenerse alerta', 'Planeación y organización', 'Trabajo en base a resultados'
]

export default function RequisicionCaracteristicas({value, onChange, showDirty}) {

    const [dirty, setDirty] = useState(false)

    function handleChange(value) {
        setDirty(true)
        onChange(value)
    }

    return (
        <div className={`${dirty && showDirty ? styles.sectionDirty : ''}`}>
            <h6>Características del Puesto</h6>
            <p>Selecciona como mínimo 3 y máximo 5</p>
            <CheckboxGroup 
                options={caracteristicasDelPuestoOptions} 
                min={3} 
                max={5}
                onChange={handleChange}
                value={value}></CheckboxGroup>
        </div>
    )

}