import React, {useState, useEffect} from 'react';
import HttpRequest from '../shared/HttpRequest';
import ListVacanteItem from './ListVacanteItem';
import VacanteInviteModal from './VacanteInviteModal';
import Fab from '../shared/Fab'
import ListRequisicionItem from './ListRequisicionItem';
import ArchivarModal from './ArchivarModal';
import ListVacanteArchivedItem from './ListVacanteArchivedItem';

function statusIncludesNuevas() {
    const location = window.location;
    return !location.search.includes('status') || location.search.includes('status=Nueva') || location.search.includes('status=any')
}

function notArchived() {
    const location = window.location;
    return !location.search.includes('archivados=true')
}

export default function Vacantes({history, location}) {

    const [vacantes, setVacantes] = useState([]);
    const [requisiciones, setRequisiciones] = useState([]);
    const [vacanteInviteModal, setVacanteInviteModal] = useState({
        open: false,
        vacanteId: null,
        vacanteEmpresa:null
    })
    const [archivarModal, setArchivarModal] = useState({
        open: false,
        requisicionId: null
    })

    useEffect(() => {
        async function fetchVacantes() {            
            const response = await HttpRequest.get('/vacantes' + location.search);
            setVacantes(response.data)
        }

        async function fetchRequisiciones() {
            if(statusIncludesNuevas() && notArchived()) {
                const response = await HttpRequest.get('/requisiciones' + location.search);
                setRequisiciones(response.data)
            } else {
                setRequisiciones([]);
            }
        }

        fetchVacantes();    
        fetchRequisiciones();

    }, [location.search])

    function handleVacanteClick(vacanteId) {
        history.push(`/vacantes/${vacanteId}/prospectos`)
    }  

    function handleVacanteEditClick(requisicionId) {
        history.push(`/vacantes/${requisicionId}/edit`)
    }

    function handleArchivarClick(requisicionId) {
        setArchivarModal({
            open: true,
            requisicionId
        })
    }

    function handleArchivarCancel() {
        setArchivarModal({
            open: false,
            requisicionId: null
        })
    }

    function handleInviteClick(vacanteId, vacanteEmpresa, vacantePuesto) {
        setVacanteInviteModal({
            open:true,
            vacanteId,
            vacanteEmpresa,
            vacantePuesto
        })
    } 

    function handleInviteCancel() {
        setVacanteInviteModal({
            open: false,
            vacanteId: null,
            vacanteEmpresa:null
        })
    }

    function handleInvited() {
        const list = vacantes.slice();
        const v = list.find(v => v._id == vacanteInviteModal.vacanteId);
        v.prospectos = (v.prospectos || 0) + 1;
        setVacantes(list);
        setVacanteInviteModal({
            open: false,
            vacanteId: null,
            vacanteEmpresa:null
        })
    }

    function handleArchived() {
        setVacantes(vacantes.filter(v => v.requisicion[0]._id !== archivarModal.requisicionId));
        setArchivarModal({
            open: false,
            requisicionId: null
        })
    }

    function handleAddClick() {
        history.push('/requisiciones/nueva')
    }

    function handleReqStatusChange(vacanteId, status, tiempoPause) {
        const newVacantes = [...vacantes];
        const req = newVacantes.find(v => v._id === vacanteId).requisicion[0];
        req.status = status;
        req.tiempoPause = tiempoPause;
        setVacantes(newVacantes)
    }

    return (
        <React.Fragment>
            {
                requisiciones.map(r => (
                    <ListRequisicionItem key={r._id}
                        requisicion={r}
                        onEditClick={handleVacanteEditClick}>
                    </ListRequisicionItem>
                ))
            }
            {
                vacantes.map(v => (
                    !v.requisicion[0].archived ?
                    <ListVacanteItem 
                        key={v._id}
                        vacante={v}
                        onClick={handleVacanteClick}
                        onInviteClick={handleInviteClick}
                        onArchivarClick={handleArchivarClick}
                        onEditClick={handleVacanteEditClick}
                        onStatusChange={handleReqStatusChange}></ListVacanteItem> :
                    <ListVacanteArchivedItem
                        key={v._id}
                        vacante={v}
                        onClick={handleVacanteClick}
                        onEditClick={handleVacanteEditClick}></ListVacanteArchivedItem>
                ))
            }
            <Fab onClick={handleAddClick}/>
            <VacanteInviteModal 
                modal={vacanteInviteModal}
                onCancel={handleInviteCancel}
                onInvited={handleInvited}></VacanteInviteModal>      
            <ArchivarModal
                modal={archivarModal}
                onCancel={handleArchivarCancel}
                onArchived={handleArchived}></ArchivarModal>      
        </React.Fragment>
    )

}