import React, {useState, useEffect} from 'react';
import FlexColumn from './FlexColumn';
import withFilterOptions from './withFilterOptions';
import {Button} from 'reactstrap'
import {withRouter} from 'react-router-dom'
import HttpRequest from './HttpRequest'
import useSession from '../hooks/useSession';

const FilterEmpresas = withFilterOptions('Empresa', () => HttpRequest.get('/buscar/requisiciones/empresas'))
const FilterSucursales = withFilterOptions('Sucursal', () => HttpRequest.get('/buscar/requisiciones/sucursales'))
const FilterVacantes = withFilterOptions('Vacante', () => HttpRequest.get('/buscar/requisiciones/vacantes'))
const FilterStatus = withFilterOptions('Estatus', () => HttpRequest.get('/buscar/requisiciones/status'))

function FilterRequisiciones({history}) {

    const [userData, login, logout] = useSession();

    const [filters, setFilters] = useState({
        empresa: 'any',
        sucursal: 'any',
        vacante: 'any',
        status: 'any'
    })

    function handleChange(name) {
        return function(event) {
            setFilters({...filters, [name]:event.target.value})
        }
    }

    function handleClickApply() {
        let query = Object.keys(filters).reduce((pv, cv, i) => { 
            return `${pv}${i > 0 ? '&':''}${cv}=${filters[cv]}`
        }, '?')
        history.push('/requisiciones' + query)
    }

    return (
        <FlexColumn>
            {userData.admin ?
            <>
                <FilterEmpresas value={filters.empresa} onChange={handleChange('empresa')}></FilterEmpresas>
                <FilterSucursales value={filters.sucursal} onChange={handleChange('sucursal')}></FilterSucursales>
                <FilterVacantes value={filters.vacante} onChange={handleChange('vacante')}></FilterVacantes>
            </> :''
            }
            <FilterStatus value={filters.status} onChange={handleChange('status')}></FilterStatus>
            <br/>
            <Button style={{width:'100%'}} className="btn-gray" 
            onClick={handleClickApply}>APLICAR</Button>
        </FlexColumn>
    )

}

export default withRouter(FilterRequisiciones)