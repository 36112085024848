import React, { useState, useEffect } from 'react'
import SectionHeader from '../shared/SectionHeader'
import FlexRow from '../shared/FlexRow';
import FlexColumn from '../shared/FlexColumn';
import { FormGroup, Input, Table, Button, Label } from 'reactstrap';
import usePostHttpRequest from '../hooks/usePostHttpRequest'
import FileInput from '../shared/FileInput'
import AddAsignacionModal from './AddAsignacionModal';
import AddOnboardingModal from './AddOnboardingModal';
import useToggle from '../hooks/useToggle';

export default function ResultadoFinal({ vacanteId, prospectoId, paso, onSaved }) {

    const [resultado, setResultado] = useState({
        resultado: 'aceptado',
        comentario: ''
    })
    const [status, setStatus] = useState('pending')
    const [working, doPost, postResult] = usePostHttpRequest();
    const [file, setFile] = useState(null)
    const [isModalOpenAsignacion, toggleModalAsignacion] = useToggle(false);
    const [isModalOpenOnboarding, toggleModalOnboarding] = useToggle(false);
    const [active, setActive] = useState(null);

    useEffect(() => {
        if (paso.datos && paso.datos.resultado) {
            setResultado(paso.datos)
        }
        if (paso.status) {
            setStatus(paso.status)
        }
    }, [paso.datos, paso.status])

    useEffect(() => {
        if (postResult) {
            onSaved(postResult.data)
        }
    }, [postResult])

    function handleChange(name) {
        return function (event) {
            setResultado({ ...resultado, [name]: event.target.value })
        }
    }

    function handleFileChange(file) {
        setFile(file);
    }

    function handleAsignacion() {
        toggleModalAsignacion();
    }
    function handleOnboarding() {
        toggleModalOnboarding();
    }

    function handleGuardar(event) {
        event.preventDefault();
        event.stopPropagation();

        const formData = new FormData();
        formData.append('oficio', file);
        formData.append('stepData', JSON.stringify({
            stepName: 'resultado',
            stepData: resultado
        }));

        doPost(`/prospectos/prospecto/${vacanteId}/${prospectoId}/proceso`, formData, true)
    }

    return (
        <React.Fragment>
            <SectionHeader textAlign="left">
                RESULTADO FINAL
            </SectionHeader>
            <FlexColumn justifyContent="start" className="Left">
                <FlexRow alignItems="flex-end">
                    <FormGroup className="NoFlex">
                        <Label>Resultado</Label>
                        <Input type="select" style={{ width: '200px' }}
                            onChange={handleChange('resultado')}
                            value={resultado.resultado}>
                            <option value="seleccionar">Seleccionar</option>
                            <option value="aceptado">Aceptado</option>
                            <option value="rechazado">Declinado</option>
                        </Input>
                    </FormGroup>
                </FlexRow>
                <FormGroup>
                    <Label>Campo libre para comentarios del prospecto</Label>
                    <Input type="textarea"
                        onChange={handleChange('comentario')}
                        value={resultado.comentario}>
                    </Input>
                </FormGroup>
                <br />
                <FormGroup className="NoFlex">
                    <Label>ASIGNACIÓN</Label>
                </FormGroup>
                <FormGroup className="NoFlex">
                    <FlexRow alignItems="flex-end">
                        <FileInput maxSize={10}
                            style={{ marginTop: '5px' }}
                            fileId="oficio"
                            fileUrl={resultado.oficio}
                            working={working}
                            onChange={handleFileChange}></FileInput>
                        <Button className="btn-white"
                            style={{ margin: '10px' }}
                            disabled={working}
                            onClick={handleAsignacion}>Ver</Button>
                    </FlexRow>
                    <AddAsignacionModal isOpen={isModalOpenAsignacion}
                        prospectoId={prospectoId}
                        vacanteId={vacanteId}
                        onComplete={handleAsignacion}
                        onCancel={toggleModalAsignacion}></AddAsignacionModal>
                </FormGroup>
                <FormGroup className="NoFlex">
                    <Label>ONBORDING</Label>
                </FormGroup>
                <FormGroup className="NoFlex">
                    <FlexRow alignItems="flex-end">
                        <FileInput maxSize={10}
                            style={{ marginTop: '5px' }}
                            fileId="onbording"
                            fileUrl={resultado.onbording}
                            working={working}
                            onChange={handleFileChange}></FileInput>
                        <Button className="btn-white"
                            style={{ margin: '10px' }}
                            disabled={working}
                            onClick={handleOnboarding}>Ver</Button>
                    </FlexRow>
                    <AddOnboardingModal isOpen={isModalOpenOnboarding}
                        prospectoId={prospectoId}
                        vacanteId={vacanteId}
                        onComplete={handleOnboarding}
                        onCancel={toggleModalOnboarding}></AddOnboardingModal>
                </FormGroup>
                <br />
                <FlexRow justifyContent="flex-end">
                    <Button className="btn-white"
                        style={{ margin: '10px' }}
                        disabled={working}
                        onClick={handleGuardar}>Guardar</Button>
                    {/* {
                            status == 'current' ? 
                            <Button className="btn-white" style={{margin:'10px'}} disabled={working}>Mostrar</Button>
                            : null
                        }                     */}
                </FlexRow>
            </FlexColumn>
        </React.Fragment >
    )
}