import React from 'react';
import FlexColumn from '../shared/FlexColumn'
import moment from 'moment-timezone'
import { Button } from 'reactstrap';
import ConfirmationDialog from '../shared/ConfirmationDialog';
import HttpRequest from '../shared/HttpRequest';

export default function RejectedByClientPanel({prospecto, onRejected}) {


    async function handleConfirm() {
        await HttpRequest.post(`/prospectos/prospecto/${prospecto.vacanteId}/${prospecto._id}/status`, {
            status: 'descartado'
        }); 
        onRejected()
    }

    return (
        <ConfirmationDialog
            title="Mover a descartados"
            body="Se moverá este prospecto a descartados">
            {
                onConfirm => (
                    <FlexColumn>
                        <div className="ProspectoWarning">
                            <p>
                                Este prospecto ha sido rechazado por el cliente <br/>
                                Razón: {prospecto.rejectedByClient.reason} <br/>
                                Fecha: {moment(prospecto.rejectedByClient.updatedAt).tz("America/Hermosillo").format('YYYY-MM-DD')}
                            </p>
                            <Button onClick={onConfirm(handleConfirm)}>Mover a descartados</Button>
                        </div>
                    </FlexColumn>
                )
            }    
        </ConfirmationDialog>        
    )
}