import React, {useState} from 'react';
import HttpRequest from '../shared/HttpRequest';
import FlexRow from '../shared/FlexRow'
import FlexColumn from '../shared/FlexColumn';
import Date from '../shared/Date';
import {TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap'
import ProspectosTable from './ProspectosTable'

const TABS = {
    PARTICIPANTES: 'Participantes',
    FINALISTAS: 'Finalistas',
    DESCARTADOS: 'Descartados'
}

export default function Prospectos({match, history}) {

    const [vacante, setVacante] = useState({
        requisicion: [
            {datosGenerales:{}}
        ]
    })
    const [participantes, setParticipantes] = useState([]);
    const [finalistas, setFinalistas] = useState([]);
    const [descartados, setDescartardos] = useState([]);
    const [tab, setTab] = useState(TABS.PARTICIPANTES)

    useState(() => {
        async function fetchProspectos(setState, status) {
            const response = await HttpRequest.get(`/prospectos/${match.params._id}/${status}`)
            setState(response.data);
        }
        fetchProspectos(setParticipantes,'participante');
        fetchProspectos(setFinalistas,'finalista');
        fetchProspectos(setDescartardos,'descartado');
    }, [match.params._id])

    useState(() => {
        async function fetchVacante() {
            const response = await HttpRequest.get(`/vacantes/${match.params._id}`);
            setVacante(response.data);
        }
        fetchVacante();
    }, [match.params._id])

    function handleTabClick(tabName) {
        return function(event) {
            if(tab !== tabName) {
                setTab(tabName);
            }            
        }
    }

    function handleProspectoClick(prospectoId) {
        history.push(`/vacantes/${match.params._id}/prospectos/${prospectoId}`)
    }

    function handleBackClick() {
        history.goBack()
    }

    const datosGenerales = vacante.requisicion[0].datosGenerales;

    return (
        <FlexColumn justifyContent="center" style={{textAlign:'center'}}>
            <div className="Link" style={{textAlign:'left'}}
                onClick={handleBackClick}>{'< Atrás'}</div>
            <h3 className="FontBlue">{datosGenerales.empresa} - {datosGenerales.nombrePuesto}</h3>
            <div className="FontBlue" style={{fontWeight:'bold', textAlign:'left'}}>
                {vacante.prospectos}/{datosGenerales.numeroVacantes} Invitados
            </div>
            <Nav tabs className="ProspectosTabs">
                <NavItem>
                    <NavLink 
                        className={tab == TABS.PARTICIPANTES ? 'active' : ''}
                        onClick={handleTabClick(TABS.PARTICIPANTES)}>{TABS.PARTICIPANTES} ({participantes.length})</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink 
                        className={tab == TABS.FINALISTAS ? 'active' : ''}
                        onClick={handleTabClick(TABS.FINALISTAS)}>{TABS.FINALISTAS} ({finalistas.length})</NavLink>
                </NavItem>
                <NavItem>
                    <NavLink 
                        className={tab == TABS.DESCARTADOS ? 'active' : ''}
                        onClick={handleTabClick(TABS.DESCARTADOS)}>{TABS.DESCARTADOS} ({descartados.length})</NavLink>
                </NavItem>                
            </Nav>
            <TabContent activeTab={tab}>
                <TabPane tabId={TABS.PARTICIPANTES}>
                    <ProspectosTable onClick={handleProspectoClick} prospectos={participantes}></ProspectosTable>
                </TabPane>
                <TabPane tabId={TABS.FINALISTAS}>
                    <ProspectosTable onClick={handleProspectoClick} prospectos={finalistas}></ProspectosTable>
                </TabPane>
                <TabPane tabId={TABS.DESCARTADOS}>
                    <ProspectosTable onClick={handleProspectoClick} prospectos={descartados}></ProspectosTable>
                </TabPane>
            </TabContent>
        </FlexColumn>
    );
}