import React, {useState, useEffect} from 'react';
import {Input} from 'reactstrap';
import FlexColumn from './FlexColumn';
import FlexRow from './FlexRow';
import styles from './EmailChips.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default function EmailChips({onAddEmail, onDeleteEmail, emails}) {

    const [value, setValue] = useState('')

    function handleChange(event) {    
        setValue(event.target.value);
    }

    function handleKeyDown(event) {
        if (['Enter', 'Tab'].includes(event.key)) {
            event.preventDefault();
            event.stopPropagation();
            const email = event.target.value.trim().toLowerCase();
            if(validateEmail(email) && !emails.includes(email)) {
                onAddEmail(email)
                setValue('')
            }
        }
    }

    function handleClickDeleteEmail(email) {
        return function(event) {
            onDeleteEmail(email)
        }
    }

    return (
        <FlexColumn style={{
            marginTop:'10px'
        }}>
            <p>Invitados adicionales</p>
            <FlexRow>
                <Input type="text"
                    value={value}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    className={styles.emailInput}
                    placeholder="Teclea un correo y presiona Enter"></Input>
            </FlexRow>
            <p>
                {
                    emails.map(email => (
                        <span key={email}
                            className={styles.chip}>
                                <span>{email}</span>
                                <span> </span>
                                <span className={styles.delete} onClick={handleClickDeleteEmail(email)}>
                                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                </span>
                        </span>
                    ))
                }
            </p>
        </FlexColumn>
    )
}