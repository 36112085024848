import React, {useState, useEffect, useCallback} from 'react';
import {Button, Form, FormGroup, Input, Label, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import useSession from './hooks/useSession';
import FlexColumn from './shared/FlexColumn';
import usePostHttpRequest from './hooks/usePostHttpRequest';
import './Login.css'
import FlexRow from './shared/FlexRow';
import LoginLeftImg from './images/login_left_img.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons'

export default function Login() {

    const [jwt, login, logout] = useSession();
    const [working, doPost, loginResponse] = usePostHttpRequest();
    const [error, setError] = useState('');

    const [data, setData] = useState({
        username: '',
        password:''
    })
    

    useEffect(() => {
        if(!loginResponse) {
            return;
        }
        if(!loginResponse.data || !loginResponse.data.success) {
            setError('Datos no válidos');
            return;
        }
        login(loginResponse.data.token);
    }, [loginResponse])

    function handleInputChange(name) {
        return function(event) {
            setData({...data, [name]:event.target.value});
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        setError('');
        doPost('/session/login/reclutador', data);
    }

    return(
        <div className="loginBackground">
            <div className="loginContainer">
                <FlexRow>
                    <div className="loginLeftColumn">
                        <img src={LoginLeftImg} alt="login img" style={{width:'100%', height:'100%'}}/>
                    </div>
                    <Form onSubmit={handleSubmit} className="loginForm">
                        <FlexColumn justifyContent="center" alignItems="center">
                            <h5>INICIAR SESIÓN</h5>                            
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="append" >
                                        <InputGroupText style={{background:'white', borderRight:'none'}}>
                                            <FontAwesomeIcon icon={faUser}/>
                                        </InputGroupText>                                        
                                    </InputGroupAddon>
                                  <Input placeholder="Usuario" 
                                    type="text" 
                                    value={data.username}
                                    onChange={handleInputChange('username')}
                                    style={{borderLeft:'none'}}
                                    required/>                                  
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="append" >
                                        <InputGroupText style={{background:'white', borderRight:'none'}}>
                                            <FontAwesomeIcon icon={faLock}/>
                                        </InputGroupText>                                        
                                    </InputGroupAddon>
                                  <Input type="password" 
                                    placeholder="Contraseña"
                                    value={data.password}
                                    onChange={handleInputChange('password')}
                                    style={{borderLeft:'none'}}
                                    required/>                              
                                </InputGroup>
                            </FormGroup>
                            <br/>
                            <p className="FontRed">{error}</p>
                            <Button type="submit" 
                                disabled={working}
                                className="btn-blue">Entrar</Button>
                        </FlexColumn>
                    </Form>
                </FlexRow>
            </div>
        </div>        
    )

}