import React from 'react';

export default function FlexRow({children, alignItems, justifyContent, style, flexWrap, ...rest}) {    

    return (
        <div style={{display:'flex', flexDirection:'row', flexWrap: flexWrap || 'wrap', 
            alignItems:alignItems || 'center',
            justifyContent:justifyContent || 'inherit',
            ...style}}            
            {...rest}>
            {children}
        </div>
    )
}