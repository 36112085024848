import React, {useState, useEffect, useCallback} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button,
    Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input} from 'reactstrap';
import FlexRow from '../shared/FlexRow';
import {CALENDAR_EVENT_LABELS, CALENDAR_EVENT_TYPES} from '../shared/Utils'
import HttpRequest from '../shared/HttpRequest';
import moment from 'moment';
import { faPen, faTrash, faVideo } from '@fortawesome/free-solid-svg-icons'
import IconButton from '../shared/IconButton';
import ConfirmIconButton from '../shared/ConfirmIconButton'
import ReactDatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker'
import es from 'date-fns/locale/es'
import useToggle from '../hooks/useToggle'
import "react-datepicker/dist/react-datepicker.css";
import FlexColumn from './FlexColumn';
import Checkbox from './Checkbox';
import FastInput from './FastInput';
import EmailChips from './EmailChips';
import useFetch from '../hooks/useFetch';
import StartVideoCallModal from './StartVideoCallModal';

registerLocale('es', es)

function getNextHour() {
    const date = moment();
    date.add(1, 'hour');
    date.minutes(0);
    date.seconds(0);
    return date;
}

export default function CalendarEventDropdown({type, vacanteId, prospectoId, onEventsLoaded, onEventDeleted}) {

    const [isOpen, setIsOpen] = useState(false)
    const [events, setEvents] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [working, setWorking] = useState(false)    
    const [showVideoCallModal, toggleVideoCallModal] = useToggle(false)
    const [currentEvent, setCurrentEvent] = useState({
        inicio: getNextHour().toDate(),
        fin: getNextHour().add(30, 'minutes').toDate(),
        invitarProspecto: true,
        agendarVideollamada: false,
        comentario: '' ,
        direccion: ''       
    });      
    const [invitados, setInvitados] = useState([]);
    const [fetchingDayEvents, dayEvents, fetchDayEvents] = useFetch([])
    const multiple = type === 'postAsignacion'; 
    
    const fetchEvents = useCallback(async () => {
        
        const response = await HttpRequest.get(`/eventos/${vacanteId}/${prospectoId}/${type}`);
        setEvents(response.data);
        
    }, [vacanteId, prospectoId, type])

    useEffect(() => {
        onEventsLoaded(events)
    }, [events, onEventsLoaded])

    useEffect(() => {        
        if(vacanteId && prospectoId) {
            fetchEvents();
        }
        
    }, [vacanteId, prospectoId, fetchEvents])

    useEffect(() => {

        if(modalOpen && currentEvent.inicio) {
            // nos traemos los eventos del dia para mostrarlos al reclutador
            fetchDayEvents(`/eventos/dia/${currentEvent.inicio.getTime()}`);
            // la fecha de termino del evento tiene que ser despues de la fecha de inicio
            setCurrentEvent(event => {
                return {
                    ...event,
                    fin: moment(currentEvent.inicio).add(30, 'minutes').toDate()
                }
            })
        }

    }, [currentEvent.inicio, modalOpen, fetchDayEvents])
    

    function toggle() {
        setIsOpen(!isOpen)
    }   

    function toggleModal() {
        const newState = !modalOpen;
        if(!newState) {
            setCurrentEvent({...currentEvent})
        } 
        setModalOpen(!modalOpen)
    }

    function handleChange(name) {
        return function(event) {
            setCurrentEvent({
                ...currentEvent,
                [name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
            })
        }
    }

    function handleDateChange(type) {
        return function(date) {
            setCurrentEvent({
                ...currentEvent,
                [type]: date
            })
        }
    }
    
    function handleEventClick(i) {
        return function(event) {
            // setCurrentEvent({
            //     _id: events[i]._id,
            //     selectedDate: events[i].day,
            //     time: events[i].time
            // })
            // setModalOpen(true);
        }
    }

    function handleClickCalendarEvent() {
        if(events.length > 0) {
            window.open(events[0].google.htmlLink, '_blank');
        } else {
            toggleModal()
        }
    }

    function handleClickCalendarMultipleEvent(i) {
        return function() {
            const event = events[i];
            window.open(event.google.htmlLink, '_blank');
        }
    }

    function handleDeleteClick(i) {
        return async function(event) {
            setWorking(true);
            const deleted = events[i];
            await HttpRequest.post(`/eventos/${vacanteId}/${prospectoId}/${deleted._id}/delete`)
            setWorking(false);            
            setEvents(events.filter(ev => ev._id != deleted._id))
            const d = moment(deleted.day);
            onEventDeleted({
                label: `${d.month()}-${d.date()}`,
                _id: deleted._id
            })
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        setWorking(true);

        const data = {
            _id: currentEvent._id,
            eventType: type,
            ...currentEvent,
            invitados
        }

        if(currentEvent._id) {
            await HttpRequest.post(`/eventos/${vacanteId}/${prospectoId}/${currentEvent._id}/update`, data)
        } else {
            await HttpRequest.post(`/eventos/${vacanteId}/${prospectoId}`, data)
        }
        
        setWorking(false);
        setModalOpen(false);
        fetchEvents();
    }

    function handleAddInvitado(email) {
        setInvitados([...invitados, email]);
    }

    function handleDeleteInvitado(email) {
        setInvitados(invitados.filter(e => e !== email));
    }

    function handleClickVideo(eventData) {
        return function() {
            toggleVideoCallModal();
        }        
    }

    return (
        <React.Fragment>
            <FlexRow>
                <div className="CalendarEventDropdownLabel">{CALENDAR_EVENT_LABELS[type]}</div>
                {
                    multiple ?
                    <Dropdown isOpen={isOpen} toggle={toggle} className="CalendarEventDropdown">
                        <DropdownToggle caret className="CalendarEventDropdownToggle">            
                            Agendar
                        </DropdownToggle>
                        <DropdownMenu>
                            {
                                events.length === 0 ?
                                <DropdownItem>Sin eventos</DropdownItem> :
                                events.map((ev, i) => (
                                    <DropdownItem key={i}                                 
                                        onClick={handleClickCalendarMultipleEvent(i)}
                                        style={{textAlign:'center', cursor:'pointer'}}>
                                        <FlexRow>
                                            <span>
                                                {moment(events[0].google.start.dateTime).format('DD/MM/YYYY HH:mm')}
                                            </span>
                                            <div style={{flexGrow:1}}></div>
                                            {/* <IconButton icon={faPen} onClick={handleEventClick(i)} className="FontGray"></IconButton> */}
                                            {/* <ConfirmIconButton 
                                                style={{marginLeft:'10px'}}
                                                icon={faTrash} 
                                                className="FontGray"
                                                onClick={handleDeleteClick(i)}></ConfirmIconButton> */}
                                        </FlexRow>
                                    </DropdownItem>
                                ))
                            }
                            {/* <DropdownItem>lklk</DropdownItem> */}
                            <DropdownItem divider/>
                            <DropdownItem onClick={toggleModal}>
                                <span className="FontGreen">+ Agregar</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown> :
                    <FlexRow>
                        <Button className="btn-white" onClick={handleClickCalendarEvent}>
                        {
                            events.length === 0 ?
                            'Agendar' :
                            moment(events[0].google.start.dateTime).format('DD/MM/YYYY HH:mm')
                        }
                        </Button>
                        {
                            events.length === 1 && events[0].videoCallData ?
                            <React.Fragment>
                                <IconButton icon={faVideo} className="FontGray" onClick={handleClickVideo(events[0])}></IconButton>
                                <StartVideoCallModal isOpen={showVideoCallModal} 
                                eventId={events[0]._id}
                                onClose={toggleVideoCallModal}></StartVideoCallModal>
                            </React.Fragment> : null
                        }
                    </FlexRow>
                }             
            </FlexRow>            
            <Modal isOpen={modalOpen}>
                <ModalHeader toggle={toggleModal}>
                    Agendar {CALENDAR_EVENT_LABELS[type]}
                </ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleSubmit}>
                        <p>
                            Estás por agendar un(a) {CALENDAR_EVENT_LABELS[type]}. Por favor selecciona 
                            la fecha de inicio y fin del evento
                        </p>
                        <FlexRow justifyContent="space-around">
                            <div>
                                <span style={{fontSize:'18px'}} className="Bold">De: </span>
                                <ReactDatePicker
                                    selected={currentEvent.inicio}
                                    onChange={handleDateChange('inicio')}
                                    showTimeSelect
                                    locale="es"
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Hora"
                                    dateFormat="dd/MM/yyyy h:mm aa"></ReactDatePicker>
                            </div>
                            <div>
                                <span style={{fontSize:'18px'}} className="Bold">A: </span>
                                <ReactDatePicker
                                    selected={currentEvent.fin}
                                    onChange={handleDateChange('fin')}
                                    showTimeSelect
                                    minDate={currentEvent.inicio}
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="Hora"
                                    dateFormat="dd/MM/yyyy h:mm aa"></ReactDatePicker>
                            </div>                            
                        </FlexRow>
                        <br/>
                        <FlexColumn>
                            <p>Eventos en el día seleccionado:</p>
                            {
                                fetchingDayEvents ? 
                                'Cargando...' :
                                dayEvents.length === 0 ?
                                <p>Ninguno</p> :
                                <ul>
                                    {
                                        dayEvents.map(e => (
                                            <li key={e.id}>
                                                <a href={e.htmlLink} target="_blank" rel="noopener noreferrer">
                                                    {e.summary}: {moment(e.start.dateTime).format('DD/MM/YYYY HH:mm')} - {moment(e.end.dateTime).format('DD/MM/YYYY HH:mm')}
                                                </a>
                                            </li>
                                        ))
                                    }
                                </ul>
                            }
                        </FlexColumn>
                        <br/>
                        <FlexRow>
                            <Checkbox 
                                checked={currentEvent.invitarProspecto}
                                onChange={handleChange('invitarProspecto')}>Invitar a prospecto</Checkbox>                        
                        </FlexRow>
                        {
                            type === CALENDAR_EVENT_TYPES.ENTREVISTA_EMPRESA ?
                            <FlexRow>
                                <Checkbox 
                                    checked={currentEvent.agendarVideollamada}
                                    onChange={handleChange('agendarVideollamada')}>Agendar videollamada</Checkbox>                        
                            </FlexRow> : null
                        }
                        <EmailChips
                            emails={invitados}
                            onAddEmail={handleAddInvitado}
                            onDeleteEmail={handleDeleteInvitado}
                        ></EmailChips>
                        <FormGroup>
                            <Label>Dirección</Label>
                            <FastInput type="textarea" 
                                value={currentEvent.direccion}
                                onChange={handleChange('direccion')}/>
                        </FormGroup>
                        <FormGroup>
                            <Label>Comentario</Label>
                            <FastInput type="textarea" 
                                value={currentEvent.comentario}
                                onChange={handleChange('comentario')}/>
                        </FormGroup>
                        <FlexRow justifyContent='flex-end' className="FormButtons">
                            <Button type="submit" color="primary" disabled={working}>Guardar</Button>
                            <Button color="secondary" onClick={toggleModal} disabled={working}>Cancelar</Button>
                        </FlexRow>
                    </Form>
                </ModalBody> 
            </Modal>  
        </React.Fragment>
    )

}