import React, {useState} from 'react';
import {  FormGroup, Label, Button, Table } from 'reactstrap';
import FastInput from '../shared/FastInput';
import FlexColumn from '../shared/FlexColumn';
import FlexRow from '../shared/FlexRow';

const defaultValue = {
    nombre: '',
    departamento: '',
    correo: ''
}

export default function RequisicionAreasInteresadas({value, onChange}) {


    function handleChange(i, name) {
        return function(event) {
            const areas = value.slice();
            areas[i][name] = event.target.value;
            onChange(areas)
        }
    }

    return (
        <React.Fragment>
            <h6>Áreas interesadas</h6>
            <p>
                Aquí puedes agregar a otras personas que quieras involucrar en este proceso de reclutamiento. Cada 
                persona que agregues recibirá notificaciones según vaya avanzando el proceso.
            </p>
            <FlexColumn>
                {
                    value.map((a, i) => (
                        <FlexRow key={i}>
                            <FormGroup>
                                <Label>Nombre</Label>
                                <FastInput 
                                    type="text" 
                                    value={a.nombre}
                                    onChange={handleChange(i, 'nombre')}></FastInput>
                            </FormGroup>
                            <FormGroup>
                                <Label>Departamento</Label>
                                <FastInput 
                                    type="text" 
                                    value={a.departamento}
                                    onChange={handleChange(i, 'departamento')}></FastInput>
                            </FormGroup>
                            <FormGroup>
                                <Label>Correo</Label>
                                <FastInput 
                                    type="email" 
                                    value={a.correo}
                                    onChange={handleChange(i, 'correo')}></FastInput>
                            </FormGroup>
                        </FlexRow>
                    ))
                }
            </FlexColumn>
        </React.Fragment>
    )


}