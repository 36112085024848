import React, {useState, useEffect} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink} from 'reactstrap';
import styles from './VacanteEdit.module.css'
import RequisicionConf from '../requisiciones/RequisicionConf'
import RequisicionForm from '../requisiciones/RequisicionForm'

export default function VacanteEdit({match, history}) {

    const [currentTab, setCurrentTab] = useState('requisicion');
    
    return (
        <div className="VacanteEdit">
            <Nav tabs>
                <NavItem className={styles.tab}>
                    <NavLink onClick={() => setCurrentTab('requisicion')}
                        className={`${currentTab === 'requisicion' ? styles.activeTab : ''}`}>
                        <div className={styles.tabBorder}></div>
                        Requisición
                    </NavLink>                
                </NavItem>
                <NavItem className={styles.tab}>
                    <NavLink onClick={() => setCurrentTab('conf')}
                        className={`${currentTab === 'conf' ? styles.activeTab : ''}`}>
                        <div className={styles.tabBorder}></div>
                        Configura la vacante
                    </NavLink>                
                </NavItem>
            </Nav>            
            <TabContent activeTab={currentTab}>
                <TabPane tabId="requisicion">
                    <RequisicionForm match={match} history={history}></RequisicionForm>
                </TabPane>
                <TabPane tabId="conf">
                    <RequisicionConf match={match} history={history}></RequisicionConf>
                </TabPane>
            </TabContent>
        </div>
    )
}